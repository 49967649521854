import {
    Group,
    Stack,
    TextInput,
} from '@mantine/core'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import PrimaryInput from 'app/views/components/buttons/PrimaryInput';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EntityType from './entity-type';
import { loadAllEntityTypes } from 'app/state/ducks/entity-type/thunks';
import { EntityTypeParams, EntityTypes } from 'app/models/entity-type-params';
import { getEntityTypeLoader } from 'app/state/ducks/entity-type/selectors';
import { CustomLoaderPage } from 'app/views/components/loader/loader-page';
import { IconSearch } from '@tabler/icons-react';

interface props {
    tab: string
}

export function EntitiesTypeDrillPage({ tab }: props) {
    const dispatch = useDispatch()
    const [formExpanded, setFormExpanded] = useState(false);
    const [expandedEntityId, setExpandedEntityId] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const initialNewEntity: EntityTypeParams = {
        name: '',
        mandatoryFields: [] as EntityTypes[],
        isActive: false
    };

    const [newEntity, setNewEntity] = useState(initialNewEntity);
    const entitiesTypeLoader = useSelector(getEntityTypeLoader)
    const [globalLoading, setGlobalLoading] = useState(false)

    useEffect(() => {
        setGlobalLoading(entitiesTypeLoader)
    }, [entitiesTypeLoader])


    useEffect(() => {
        dispatch(loadAllEntityTypes())
    }, [])

    const handleOpenFormForCreate = () => {
        setExpandedEntityId(null);
        setFormExpanded(true);
    };


    const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; pageRender: JSX.Element; } } = {
        entitytype: {
            route: `/entitytype`,
            label: 'AVAILABLE TYPES',
            active: tab === 'entitytype' ? true : false,
            pageRender: (
                <EntityType
                    formExpanded={formExpanded}
                    setFormExpanded={setFormExpanded}
                    expandedEntityId={expandedEntityId}
                    setExpandedEntityId={setExpandedEntityId}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    newEntity={newEntity}
                    setNewEntity={setNewEntity}
                />
            ),
        },
    }

    return (
        <Stack w="100%">
            <Group w="100%" position="apart" className='tabsGapsActivity'>
                <Group position="right">
                    <SegmentControlUpdated tabs={tabs} />
                </Group>
                <Group noWrap>
                    <TextInput
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.currentTarget.value)}
                        className="search-input"
                        icon={<IconSearch size={16} />}
                    />
                    <PrimaryInput onClick={handleOpenFormForCreate} text="Create Entity Type" />
                </Group>
            </Group>
            {globalLoading ? <CustomLoaderPage /> :
                tabs[tab].pageRender}
        </Stack>
    )
}
