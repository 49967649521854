import * as types from './types'
import { EntityProfileParams } from 'app/models/entity-profile-params'
import { createReducer } from 'app/state/utils'

export interface EntityProfileState {
  entityProfiles: EntityProfileParams[]
  entityProfile?: EntityProfileParams
  isLoading: boolean
}

const initialState: EntityProfileState = {
  entityProfiles: [],
  entityProfile: undefined,
  isLoading: false,
}

const entityProfilesReducer = createReducer(initialState)({
  [types.GET_ENTITY_PROFILES]: (state: any, payload: any) => {
    return { ...state, entityProfiles: payload.payload }
  },
  [types.GET_ENTITY_PROFILE]: (state: any, payload: any) => {
    return { ...state, entityProfile: payload.payload }
  },
  [types.ADD_ENTITY_PROFILE]: (state: any, payload: any) => {
    return {
      ...state,
      entityProfiles: [payload.payload.data, ...state.entityProfiles],
    }
  },
  [types.EDIT_ENTITY_PROFILE]: (state: any, payload: any) => {
    const index = state.entityProfiles.findIndex(
      (entityProfile: { id: any }) =>
        entityProfile.id === payload.payload.data.id,
    )
    const newArray = [...state.entityProfiles]
    newArray[index] = { ...payload.payload.data }
    return {
      ...state,
      entityProfiles: newArray,
      entityProfile: { ...payload.payload.data },
    }
  },
  [types.DELETE_ENTITY_PROFILES]: (state: any, payload: any) => {
    const filteredEntityProfiles = state.entityProfiles.filter(
      (entityProfile: any) => {
        return entityProfile.id !== payload.payload[0].id
      },
    )

    return {
      ...state,
      entityProfiles: filteredEntityProfiles,
    }
  },
  [types.START_LOADING_ENTITY_PROFILES]: (state: EntityProfileState) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_ENTITY_PROFILES]: (state: EntityProfileState) => {
    return { ...state, isLoading: false }
  },
})

export default entityProfilesReducer
