export enum EntityRole {
  BorrowerRole = 'BorrowerRole',
  LenderRole = 'LenderRole',
  LetterOfCreditRole = 'LetterOfCreditRole',
  GuarantorRole = 'GuarantorRole',
  ThirdPartyRole = 'ThirdPartyRole',
  AdminAgentRole = 'AdminAgentRole',
  CustodianRole = 'CustodianRole',
  TrusteeRole = 'TrusteeRole',
  ServicerRole = 'ServicerRole',
  BusinessProcessOutsourcerRole = 'BusinessProcessOutsourcerRole',
  FundAdminRole = 'FundAdminRole',
}

export type EntityProfileParams = {
  id?: string
  name: string
  isExternalUser: boolean
  isMyCompany: boolean
  kycType: string
  roles: EntityRole[]
  isActive: boolean
  agencyAdmin?: string
  observers?: string[]
}
