import { Stack } from '@mantine/core';

interface TableListProps<T> {
  data: T[];
  renderSectionTitle: (item: T) => React.ReactNode;
  renderItem: (item: T) => React.ReactNode;
}

const TableList = <T,>({ data, renderSectionTitle, renderItem }: TableListProps<T>) => {
  return (
    <Stack w="100%">
      {data.map((item, index) => (
        <Stack key={index} w="100%" className="sectionBody tableMargin">
          {renderSectionTitle(item)}
          <Stack w="100%" className="sectionBody">
            {renderItem(item)}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default TableList;
