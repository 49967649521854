import {
  NumberInput,
  ScrollArea,
  Title,
  Select,
  Stack,
  Divider,
  Group
} from '@mantine/core'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { saveFacility } from 'app/state/ducks/facilities/thunks'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from '@mantine/form'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { EntityParams } from 'app/models/entity-params'
import { SubLimitsTypes, currencyOptions } from 'app/models/dropdown-options'
import { getColumnDefs } from './sublimits-colum-def'
import { saveFacilityAmendment } from 'app/state/ducks/facility-amendments/thunks'
import { FacilityParams } from 'app/models/facility-params'
import { getFacilityAmendment } from 'app/state/ducks/facility-amendments/selectors'
import {
  ErrorNotification,
  SuccessNotification,
} from '../notifications/notification'
import FormWrapper from '../Form/FormWrapper'
import IonIcon from '@reacticons/ionicons'
import { formatDateToUTC } from 'app/utils/util-functions'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import MainTable from '../Table/MainTable'
import { EntityRole } from 'app/models/entity-profile-params'
import { getEntityProfiles } from 'app/state/ducks/entity-profile/selectors'

type Props = {
  facility: any
  setFacility: any
  amendment?: string | null | undefined
}

interface ILendersArray {
  label: string
  value: string
  disabled: boolean
}

export interface SubLimit {
  amount: string
  currency: string
  lenders: {
    id: string
    amount: string
  }[]
  subLimitsType: string
}

export interface ChildSublimitsRef {
  handleClick: () => void
}

const Sublimits: React.ForwardRefRenderFunction<ChildSublimitsRef, Props> = (
  { facility, setFacility, amendment },
  ref
) => {
  const dispatch = useDispatch()
  const entities: EntityParams[] = useSelector(getEntities)
  const businessDate = useSelector(getBusinessDate)
  const facilityAmendment: FacilityParams = useSelector(getFacilityAmendment)
  const entityProfiles = useSelector(getEntityProfiles)

  const [lendersArray, setLendersArray] = useState<ILendersArray[]>([])
  const [borrowersArray, setBorrowersArray] = useState<ILendersArray[]>([])
  const [updatedSubLimitsTypes, setUpdatedSubLimitsTypes] = useState(SubLimitsTypes);


  const handleClick = async () => {
    const subLimits = form.getTransformedValues().sublimits
    const uniqueCombinations = new Set<string>()

    for (const obj of subLimits) {
      const combination = `${obj.subLimitsType}-${obj.currency}`
      if (uniqueCombinations.has(combination)) {
        ErrorNotification({
          title: 'Failed Facility Creation',
          message: `You are not able to create sublimit with same sublimit type and currency`,
        })
        throw new Error('Duplicate combination found.')
      }
      uniqueCombinations.add(combination)
    }

    if (form.validate().hasErrors) {
      throw new Error('Form validation failed.')
    }

    let response: any

    if (amendment) {
      response = await dispatch(
        saveFacilityAmendment(
          {
            ...facility,
            amendmentDate: facility.amendmentDate ?? formatDateToUTC(businessDate),
            subLimits: form.getTransformedValues().sublimits,
            amendmentAmortization: facility.amendmentAmortization ?? null,
            amendmentFees: facility.amendmentFees ?? [],
            amendmentLenders: facility.amendmentLenders ?? [],
          },
          facilityAmendment
        )
      )
    } else {
      response = await dispatch(
        saveFacility(
          {
            ...facility,
            subLimits: form.getTransformedValues().sublimits,
          },
          facility.id
        )
      )
    }
    if (response?.success === false) {
      ErrorNotification({
        title: ' Facility Action Failed',
        message: response?.payload || 'Check Lender Allocations and try again',
      })
      return
    }
    if (
      Object.keys(response.payload).length !== 0 ||
      response?.success === true
    ) {
      setFacility({
        ...facility,
        subLimits: form.getTransformedValues().sublimits,
      })

      SuccessNotification({
        title: 'Successful Facility Creation',
        message:
          'You can add Lender Allocations, Interest Rate Options, Fees, and Amortization Schedules',
      })
    }
  }

  // Expose the handleClick method to the parent component
  useImperativeHandle(ref, () => ({
    handleClick,
  }))

  useEffect(() => {
    const mapEntitiesToOptions = (entities: EntityParams[], role: EntityRole): ILendersArray[] => {
      return entities.reduce((acc: ILendersArray[], entity: EntityParams) => {
        const isMatchingProfile = entity.entityProfile.some(profile => entityProfiles.find(option => option.id === profile.id)?.roles.includes(role))
        if (isMatchingProfile) {
          acc.push({
            value: entity.id || '',
            label: entity.entityName,
            disabled: false,
          });
        }
        return acc;
      }, []);
    };

    const lenderAllocations = mapEntitiesToOptions(entities, EntityRole.LenderRole);
    const borrowerAllocations = mapEntitiesToOptions(entities, EntityRole.BorrowerRole);
    const matchedBorrowerAllocations = borrowerAllocations?.filter(borrowerOption =>
      facility?.borrowers?.some((facilityBorrower: { id: string }) => facilityBorrower.id === borrowerOption.value)
    );

    setLendersArray(lenderAllocations);
    setBorrowersArray(matchedBorrowerAllocations);
  }, [facility]);

  const form = useForm({
    initialValues: {
      sublimits:
        facility?.subLimits?.length > 0
          ? facility.subLimits.map(
            (sublimit: {
              currency: string
              subLimitsType: string
              lenders: [
                { lender: { id: string; admin: string }; amount: string }
              ]
              amount: string
            }) => ({
              currency: sublimit.currency || '',
              subLimitsType: sublimit.subLimitsType || '',
              lenders: sublimit.lenders.map(lender => ({
                lender: {
                  id: lender.lender.id || '',
                  admin: lender.lender.admin || '',
                },
                amount: Number(lender.amount) || '',
              })),
              amount: Number(sublimit.amount) || '',
            })
          )
          : [
            {
              currency: '',
              subLimitsType: '',
              lenders: [{ lender: { id: '', admin: '' }, amount: '' }],
              amount: '',
            },
          ],
    },

    transformValues: values => ({
      ...values,
      sublimits: values.sublimits.map(
        (item: {
          currency: string
          subLimitsType: string
          lenders: [{ lender: { id: string; admin: string }; amount: string }]
          amount: string
        }) => {
          if (item.subLimitsType == 'SpecificCurrencies') {
            return {
              ...item,
              lenders: [],
            }
          } else {
            return {
              ...item,
              lenders: item.lenders.map(lp => ({
                ...lp,
                lender: {
                  id: lp.lender.id,
                  admin:
                    entities.find(entity => entity.id === lp.lender.id)
                      ?.maker || '',
                },
                amount: lp.amount,
              })),
            }
          }
        }
      ),
    }),

    validate: {
      sublimits: {
        currency: value => (value > '' ? null : 'Select currency'),
        subLimitsType: value => (value > '' ? null : 'Select limit type'),
        amount: value => (value > '' && value > 0 ? null : 'Invalid amount'),
        lenders: {
          lender: {
            id: (value, allValues, row) => {
              const currentSubInd = row.split('.')[1]
              let errorMsg = null
              if (
                allValues.sublimits[currentSubInd].subLimitsType !=
                'SpecificCurrencies'
              ) {
                errorMsg = (value == '' || value == null) ? 'Select a Lender' : null
              }
              return errorMsg
            },
          },
          amount: (value, allValues, row) => {
            const currentSubInd = row.split('.')[1]
            let errorMsg = null
            if (
              allValues.sublimits[currentSubInd].subLimitsType !=
              'SpecificCurrencies'
            ) {
              const currentSubAmount = allValues.sublimits[currentSubInd].amount
              errorMsg = (value > '' && value > 0) ? null : 'Invalid Amount'
              errorMsg = (value <= currentSubAmount) ? null : 'Amount Must Be Less Than Sublimit'
            }
            return errorMsg
          },
        },
      },
    },
  })

  const lenderText = (currentSubIndex: number) => {
    const currentType = form.values.sublimits[currentSubIndex].subLimitsType
    switch (currentType) {
      case 'SwingLine':
        return 'Swingline Lender'
      case 'LetterOfCreditIssuance':
        return 'LC Issuer'
      case 'Borrower':
        return 'Borrower'
    }
    return 'Lender'
  }

  const changeSublimitType = (value: string, index: number) => {
    if (value === 'Borrower') {
      form.setFieldValue(`sublimits.${index}.currency`, facility?.baseCurrency ?? 'USD');
      form.setFieldValue(`sublimits.${index}.amount`, Number(facility?.amount));
    } else {
      form.setFieldValue(`sublimits.${index}.amount`, 0);
    }
    form.setFieldValue(`sublimits.${index}.lenders`, [{ lender: { id: '', admin: '' }, amount: '' }],);
    form.setFieldValue(`sublimits.${index}.subLimitsType`, value);
  }

  useEffect(() => {
    const hasBorrower = form.values?.sublimits.some((item: { subLimitsType: string }) => item.subLimitsType === 'Borrower');
    const newSubLimitsTypes = updatedSubLimitsTypes.map(option => ({
      ...option,
      disabled: hasBorrower && option.value === 'Borrower' ? true : false
    }));
    setUpdatedSubLimitsTypes(newSubLimitsTypes);
  }, [
    JSON.stringify(form.values.sublimits.map((sublimit: { subLimitsType: string }) => sublimit.subLimitsType))
  ]);

  const fieldsSubLimits = form.values?.sublimits?.map(
    (item: SubLimit, indexSubLimit: number) => {
      const sublimitLenders = form?.values?.sublimits[indexSubLimit]?.lenders ?? [];
      const selectedOptions = sublimitLenders.map((lender: { lender: { id: string } }) => lender.lender?.id);
      const fieldsLenders = item?.lenders?.map((lender, indexLenders) => {
        const selectedOption = lender?.id
        const updateOptions = (optionsArray: ILendersArray[], selectedOptions: string[], selectedOption: string) =>
          optionsArray.map(option => ({
            ...option,
            disabled: selectedOptions.includes(option.value) && option.value !== selectedOption,
          }));

        const isBorrowerType = form.values.sublimits[indexSubLimit]?.subLimitsType === 'Borrower';
        const optionsData = isBorrowerType
          ? updateOptions(borrowersArray, selectedOptions, selectedOption)
          : updateOptions(lendersArray, selectedOptions, selectedOption);

        return (
          <Stack w="100%" key={indexLenders}>
            <Stack>
              <Group noWrap w="100%">
                <Select
                  w="100%"
                  searchable
                  clearable
                  withAsterisk
                  label={lenderText(indexSubLimit)}
                  data={optionsData}
                  placeholder={lenderText(indexSubLimit)}
                  {...form.getInputProps(
                    `sublimits.${indexSubLimit}.lenders.${indexLenders}.lender.id`
                  )}
                />
                <NumberInput
                  w="100%"
                  withAsterisk
                  label={lenderText(indexSubLimit) + ' Sublimit'}
                  placeholder={lenderText(indexSubLimit) + ' Sublimit'}
                  min={0}
                  defaultValue={0}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  formatter={value =>
                    !Number.isNaN(parseFloat(value))
                      ? ` ${value}`.replace(
                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                        ','
                      )
                      : ' '
                  }
                  {...form.getInputProps(
                    `sublimits.${indexSubLimit}.lenders.${indexLenders}.amount`
                  )}
                  sx={{
                    label: {
                      fontFamily: 'Plus Jakarta Sans',
                      color: '#111928',
                      fontSize: '1em',
                    },
                    input: {
                      backgroundColor: '#F9FAFB',
                      height: '3em',
                      borderRadius: '0.7em',
                      color: '#6B7280',
                      borderWidth: '1px',
                      borderColor: '#D1D5DB',
                      fontSize: '1em',
                      fontFamily: 'Plus Jakarta Sans',
                    },
                  }}
                />

                <Group noWrap w="100%">
                  {indexLenders == 0 ? (
                    <Group position="center" noWrap w="100%"></Group>
                  ) : (
                    <Group position="center" noWrap w="100%">
                      <IonIcon name="trash-outline" className="click-ion-icon" style={{ color: "red" }}
                        onClick={() =>
                          form.removeListItem(
                            `sublimits.${indexSubLimit}.lenders`,
                            indexLenders
                          )
                        }
                      ></IonIcon>
                      <span>
                        {'Remove ' + lenderText(indexSubLimit)}
                      </span>
                    </Group>
                  )}
                </Group>
              </Group>
            </Stack>
          </Stack>
        )
      })

      return (
        <FormWrapper title={''}>
          <Stack key={indexSubLimit}>
            <Stack>
              <Group noWrap w="100%">
                <Select
                  w="100%"
                  searchable
                  clearable
                  withAsterisk
                  label="Sublimit Type"
                  data={updatedSubLimitsTypes}
                  placeholder="Select Sublimit Type"
                  {...form.getInputProps(
                    `sublimits.${indexSubLimit}.subLimitsType`
                  )}
                  onChange={(e: string) => { changeSublimitType(e, indexSubLimit) }}
                />
                <Group position="center" noWrap w="100%"></Group>
                <Group position="center" noWrap w="100%"></Group>
              </Group>
            </Stack>

            {form.values.sublimits[indexSubLimit].subLimitsType !== 'Borrower' && (
              <Stack>
                <Group noWrap w="100%">
                  <Select
                    w="100%"
                    clearable
                    searchable
                    withAsterisk
                    label="Currency"
                    data={currencyOptions}
                    placeholder="Select Currency"
                    {...form.getInputProps(`sublimits.${indexSubLimit}.currency`)}
                  />
                  <NumberInput
                    w="100%"
                    withAsterisk
                    label="Amount"
                    placeholder="Enter amount"
                    min={0}
                    defaultValue={0}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    formatter={value =>
                      !Number.isNaN(parseFloat(value))
                        ? ` ${value}`.replace(
                          /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                          ','
                        )
                        : ' '
                    }
                    {...form.getInputProps(`sublimits.${indexSubLimit}.amount`)}
                    sx={{
                      label: {
                        fontFamily: 'Plus Jakarta Sans',
                        color: '#111928',
                        fontSize: '1em',
                      },
                      input: {
                        backgroundColor: '#F9FAFB',
                        height: '3em',
                        borderRadius: '0.7em',
                        color: '#6B7280',
                        borderWidth: '1px',
                        borderColor: '#D1D5DB',
                        fontSize: '1em',
                        fontFamily: 'Plus Jakarta Sans',
                      },
                    }}
                  />
                  <Group position="center" noWrap w="100%"></Group>
                </Group>
              </Stack>
            )}

            {form.values.sublimits[indexSubLimit].subLimitsType ==
              'SpecificCurrencies' ? null : (
              <Stack>
                <Stack>
                  {fieldsLenders}
                </Stack>
                <Stack>
                  <Group noWrap w="100%">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IonIcon name="add-circle-outline" className="click-ion-icon"
                        onClick={() =>
                          form.insertListItem(
                            `sublimits.${indexSubLimit}.lenders`,
                            { lender: { id: '', admin: '' }, amount: '' }
                          )
                        }
                      ></IonIcon>
                      <span
                        style={{
                          marginLeft: '8px',
                          fontFamily: 'Plus Jakarta Sans',
                          fontWeight: 'bold',
                          fontSize: '1em',
                        }}
                      >
                        {'Add ' + lenderText(indexSubLimit)}
                      </span>
                    </div>
                  </Group>
                </Stack>
              </Stack>
            )}

            {form.values.sublimits[indexSubLimit].subLimitsType ==
              'SpecificCurrencies' ? (
              <Group noWrap w="100%">
                <Group noWrap w="100%">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IonIcon name="add-circle-outline" className="click-ion-icon"
                      onClick={() =>
                        form.insertListItem(
                          `sublimits`,
                          {
                            currency: '',
                            subLimitsType: 'SpecificCurrencies',
                            lenders: [],
                            amount: '',
                          },
                          indexSubLimit + 1
                        )
                      } />
                    <span
                      style={{
                        marginLeft: '8px',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: 'bold',
                        fontSize: '1em',
                      }}
                    >
                      Add Currency
                    </span>
                  </div>
                </Group>
              </Group>
            ) : null}

            <Group noWrap w="100%" position="center">
              <IonIcon name="trash-outline" className="click-ion-icon" style={{ color: "red" }}
                onClick={() =>
                  form.removeListItem(`sublimits`, indexSubLimit)
                } />
              <span>
                Remove Sublimit
              </span>
            </Group>
            <Divider my="sm" />
          </Stack>
        </FormWrapper >
      )
    }
  )

  return (
    <Stack>
      <Stack>{fieldsSubLimits}</Stack>
      <Stack>
        <Group>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IonIcon name="add-circle-outline" className="click-ion-icon"
              onClick={() =>
                form.insertListItem('sublimits', {
                  currency: '',
                  subLimitsType: '',
                  lenders: [{ lender: { id: '' }, amount: '' }],
                })
              } />
            <span
              style={{
                marginLeft: '8px',
                fontFamily: 'Plus Jakarta Sans',
                fontWeight: 'bold',
                fontSize: '1em',
              }}
            >
              Add Sublimit
            </span>
          </div>
        </Group>
      </Stack>

      <Stack>
        <Group noWrap w="100%">
          <Title style={{ fontSize: '2rem', marginTop: '20px' }}>
            Sublimit Summary
          </Title>
        </Group>
        <Group noWrap w="100%">
          <ScrollArea h={600} type="always">
            <MainTable
              tableName="Sublimits Table"
              withPagination={false}
              enableColumnOrdering={false}
              enableGrouping={false}
              enableColumnFilters={false}
              enableColumnActions={false}
              enableFilters={false}
              enableSorting={false}
              enableFullScreenToggle={false}
              enableTopToolbar={false}
              headerBackgroundColor='#F0EEEE'
              columnDefs={getColumnDefs(entities)}
              data={form.getTransformedValues().sublimits}
              setRow={() => { }}
            />
          </ScrollArea>
        </Group>
      </Stack>
    </Stack>
  )
}
export default React.forwardRef(Sublimits)
