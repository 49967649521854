import React, { useState } from 'react';
import { Box } from '@mantine/core';
import ControlSection from '../components/control-section';
import EntityList from './components/entity-type-list';
import EntityForm from './components/entity-type-form';
import { EntityTypeParams } from 'app/models/entity-type-params';
import { useSelector } from 'react-redux';
import { getEntityTypes } from 'app/state/ducks/entity-type/selectors';

interface EntityTypeProps {
    formExpanded: boolean;
    setFormExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    expandedEntityId: string | null;
    setExpandedEntityId: React.Dispatch<React.SetStateAction<string | null>>;
    newEntity: EntityTypeParams;
    setNewEntity: React.Dispatch<React.SetStateAction<EntityTypeParams>>;
}


function EntityType({ formExpanded,
    setFormExpanded,
    expandedEntityId,
    setExpandedEntityId,
    searchTerm,
    newEntity,
    setNewEntity, }: EntityTypeProps) {
    const entityTypes: EntityTypeParams[] = useSelector(getEntityTypes)
    const [activePage, setActivePage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const startIdx = (activePage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    const filteredEntities = entityTypes.filter((entity) =>
        entity.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const currentEntities = filteredEntities.slice(startIdx, endIdx);
    const handleItemsPerPageChange = (value: string) => {
        setItemsPerPage(parseInt(value, 10));
        setActivePage(1);
    };

    const handleOpenFormForEdit = (entityToEdit: EntityTypeParams) => {
        setFormExpanded(false);
        setNewEntity(entityToEdit);
        setExpandedEntityId(entityToEdit.id || null);
    };

    return (
        <Box sx={{ paddingTop: '20px' }}>
            {formExpanded && (
                <EntityForm
                    newEntity={undefined}
                    setFormExpanded={(expanded) => {
                        setFormExpanded(expanded);
                    }}
                />
            )}

            <EntityList
                entities={currentEntities}
                onEditEntity={handleOpenFormForEdit}
                expandedEntityId={expandedEntityId}
                setExpandedEntityId={setExpandedEntityId}
                newEntity={newEntity}
            />

            <Box sx={{ paddingTop: '20px' }}>
                <ControlSection
                    itemsPerPage={itemsPerPage}
                    activePage={activePage}
                    totalItems={filteredEntities.length}
                    onItemsPerPageChange={handleItemsPerPageChange}
                    onPageChange={setActivePage}
                    onExport={() => console.log('Exporting CSV')}
                />
            </Box>
        </Box>
    );
}

export default EntityType;