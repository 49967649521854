import React from 'react';
import { Stack, Group, Text, Radio } from '@mantine/core';
import { TradeEntrySearchIcon } from './trade-entry-search-icon';
import { TextLabelInput } from 'app/views/components/inputs/TextLabelInput';

interface Props {
  title: string;
  radio?: boolean;
}

export function TradeEntryCounterParty({ title, radio }: Props) {
  return (
    <Stack className="tradeEntryCounterpartiesSection">
      <Group noWrap position="apart" className="tradeEntryCounterpartiesTitleSection">
        <Text w="25%" className="tradeEntryCounterpartiesTitle">
          {title}
        </Text>
        {radio ? (
          <Group w="75%" noWrap position="apart">
            <Radio.Group name="tradeEntryRadioButtons">
              <Group>
                <Radio
                  size="lg"
                  className="tradeEntryRadioButtons"
                  labelPosition="left"
                  label="Buy"
                  value="counterPartyBuy"
                />
                <Radio
                  size="lg"
                  className="tradeEntryRadioButtons"
                  labelPosition="left"
                  label="Sell"
                  value="counterPartySell"
                />
              </Group>
            </Radio.Group>
            <TradeEntrySearchIcon />
          </Group>
        ) : (
          <TradeEntrySearchIcon />
        )}
      </Group>
      <Group noWrap className="tradeEntryCounterpartiesNameSection">
        <TextLabelInput label="Name">Search Entity Name</TextLabelInput>
      </Group>
      <Group noWrap className="tradeEntryCounterpartiesSubSections">
        <Group noWrap className="tradeEntryCounterpartiesEntry">
          <TextLabelInput label="Entity Type" divider={false}>
            Entity Type
          </TextLabelInput>
        </Group>
        <Group noWrap className="tradeEntryCounterpartiesEntry">
          <TextLabelInput label="MEI" divider={false}>
            111111
          </TextLabelInput>
        </Group>
      </Group>
      <Group noWrap className="tradeEntryCounterpartiesSubSections">
        <Group noWrap className="tradeEntryCounterpartiesEntry">
          <TextLabelInput label="Internal ID" divider={false}>
            232323
          </TextLabelInput>
        </Group>
        <Group noWrap className="tradeEntryCounterpartiesEntry">
          <TextLabelInput label="LendOS ID" divider={false}>
            555555
          </TextLabelInput>
        </Group>
      </Group>
    </Stack>
  );
}
