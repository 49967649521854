import { KpiProps } from 'app/views/components/KPI/KPI'
import { useKPIFilters } from 'app/views/components/KPI/KPI-filters'
import { TradeClosingPageData } from './trade-closing-drill-page'
import { useEffect } from 'react'

export const getKPIData = (
  data: TradeClosingPageData[] | undefined,
  setData: React.Dispatch<React.SetStateAction<TradeClosingPageData[]>>,
): KpiProps[] => {
  if (!data) {
    return []
  }

  const filterFunctions: Record<
    string,
    (trade: TradeClosingPageData) => boolean
  > = {
    Subtrades: (trade: TradeClosingPageData) =>
      ['Pending Submit', 'Pending Review'].includes(trade.status),
    'Ready to Review Trades': (trade: TradeClosingPageData) =>
      ['Pending Approval'].includes(trade.status),
    'Trades Pending Signing': (trade: TradeClosingPageData) =>
      ['New Borrowing'].includes(trade.status),
    'Pending Affiliate': (trade: TradeClosingPageData) =>
      ['Released'].includes(trade.status),
  }

  const { applyFilter, appliedFilters } = useKPIFilters({
    data,
    filterFunctions,
    setData,
  })

  useEffect(() => {
    if (appliedFilters.size === 0) return;
    applyFilter(null);
  }, [data]);

  const parentTrades = data
  const subtrades = data.filter(row =>
    ['Pending Submit', 'Pending Review'].includes(row.status),
  )
  const readyToReviewTrades = data.filter(row =>
    ['Pending Approval'].includes(row.status),
  )
  const tradesPendingSigning = data.filter(row =>
    ['New Borrowing'].includes(row.status),
  )
  const pendingAffiliate = data.filter(row => ['Released'].includes(row.status))

  return [
    {
      title: 'Parent Trades',
      amount: parentTrades.length,
      activeFilter: appliedFilters.size === 0,
      event: () => applyFilter('Parent Trades'),
    },
    {
      title: 'Subtrades',
      amount: subtrades.length,
      activeFilter: appliedFilters.has('Subtrades'),
      event: () => applyFilter('Subtrades'),
    },
    {
      title: 'Ready to Review Trades',
      amount: readyToReviewTrades.length,
      percentage: Math.round((readyToReviewTrades.length / data.length) * 100),
      activeFilter: appliedFilters.has('Ready to Review Trades'),
      event: () => applyFilter('Ready to Review Trades'),
    },
    {
      title: 'Trades Pending Signing',
      amount: tradesPendingSigning.length,
      activeFilter: appliedFilters.has('Trades Pending Signing'),
      event: () => applyFilter('Trades Pending Signing'),
    },
    {
      title: 'Pending Affiliate',
      amount: pendingAffiliate.length,
      activeFilter: appliedFilters.has('Pending Affiliate'),
      event: () => applyFilter('Pending Affiliate'),
    },
  ]
}
