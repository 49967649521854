import React from 'react'
import { Grid, Col, Select, TextInput, NumberInput } from '@mantine/core'
import { EntityParams } from 'app/models/entity-params'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import { EntityRole } from 'app/models/entity-profile-params'
import { useSelector } from 'react-redux'
import { getEntityProfiles } from 'app/state/ducks/entity-profile/selectors'

interface AssignmentSelectBoxProps {
  form: {
    values: {
      facilityId: string
      assignment: {
        from: string
        to: string
        id: string
        amount: number
        eligibility: string
      }
    }
    getInputProps: (field: string) => any
  }
  lendersPosition: any[]
  disabled: boolean
  entities: Array<EntityParams>
  currency: string
  lenderEligibilityCriteriaOptions: Array<{
    label: string
    value: string
  }>
  onFromChange: (entityId: string) => void
}

const AssignmentSelectBox: React.FC<AssignmentSelectBoxProps> = ({
  form,
  lendersPosition,
  entities,
  lenderEligibilityCriteriaOptions,
  disabled,
  currency,
  onFromChange,
}) => {
  const labelStyles = {
    fontSize: '1vw',
  }
  const entityProfiles = useSelector(getEntityProfiles)

  const handleFromChange = (value: string) => {
    onFromChange(value)
  }

  const filteredEntities = entities
    ? entities
      .filter(entity => {
        // Filter based on the entity profile
        const matchesProfile =
          entity.entityProfile.some(profile => entityProfiles.find(option => option.id === profile.id)?.roles.includes(EntityRole.LenderRole))

        // Check if the entity is not the currently selected entity
        const notCurrentlySelected =
          entity.id !== form.getInputProps('assignment.from').value

        return matchesProfile && notCurrentlySelected
      })
      .map(entity => ({ label: entity.entityName, value: entity.id ?? '' }))
    : []

  return (
    <Grid>
      <Col span={3}>
        <Select
          w="100%"
          label="From"
          placeholder="From Lender"
          searchable
          data={(lendersPosition || []).map(lender => ({
            label:
              entities.find(entity => entity.id == lender.lender.id)
                ?.entityName ?? '',
            value: lender.lender.id || '',
          }))}
          onChange={handleFromChange}
          value={form.values.assignment.from}
          styles={{
            label: labelStyles,
          }}
          disabled={disabled}
        />
      </Col>
      <Col span={3}>
        <Select
          w="100%"
          searchable
          label="To"
          placeholder="To lender"
          data={filteredEntities}
          {...form.getInputProps('assignment.to')}
          styles={{
            label: labelStyles,
          }}
          disabled={form.values.assignment.from === '' || disabled}
        />
      </Col>
      <Col span={3}>
        <NumberInput
          pb={5}
          label="Amount"
          id="amount"
          placeholder="Enter Amount"
          parser={value => value.replace(/[^0-9.]/g, '')}
          formatter={value =>
            !Number.isNaN(parseFloat(value))
              ? formatNumberToCurrency(Number(
                parseFloat(
                  value.replace(/\B(?<!\d*)(?=(\d{3})+(?!\d))/g, ',')
                )
              ),
                currency ?? 'USD')
              : ''
          }
          {...form.getInputProps('assignment.amount')}
          styles={{
            label: labelStyles,
          }}
          disabled={disabled}
        />
      </Col>
      <Col span={3}>
        <TextInput
          w="100%"
          label="Trade ID"
          name="Trade ID"
          id="id"
          placeholder="Enter Trade ID"
          {...form.getInputProps('assignment.id')}
          styles={{
            label: labelStyles,
          }}
          disabled={disabled}
        />
      </Col>
      <Col span={3}>
        <Select
          searchable
          w="100%"
          placeholder="Select Lender Eligibility"
          label="Assignment Eligibility"
          name="assignmentEligibility"
          data={lenderEligibilityCriteriaOptions ?? []}
          {...form.getInputProps('assignment.eligibility')}
          styles={{
            label: labelStyles,
          }}
          disabled={disabled}
        />
      </Col>
    </Grid>
  )
}

export default AssignmentSelectBox
