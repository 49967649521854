import React from 'react';
import { Paper, Group, Badge, Button } from '@mantine/core';
import EntityName from '../../components/entity-name';
import { EntityProfileParams } from 'app/models/entity-profile-params';
import { useDispatch } from 'react-redux';
import { removeEntityProfiles } from 'app/state/ducks/entity-profile/thunks';
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification';


interface EntityItemProps {
    entity: EntityProfileParams;
    isExpanded: boolean;
    onEdit: () => void;
}

const EntityItem: React.FC<EntityItemProps> = ({
    entity,
    isExpanded,
    onEdit,
}) => {
    const dispatch = useDispatch()

    const handleDeleteEntity = async (entityId: string) => {
        const result: any = await dispatch(removeEntityProfiles(entityId));
        if (!result.success) {
            ErrorNotification({ message: result.payload, title: 'Delete Entity Profile Failed' });
            return;
        }
        SuccessNotification({
            message: 'The entity profile has been successfully deleted.',
            title: 'Delete Successful',
        });
    };

    return (
        <Paper
            p="md"
            radius="md"
            className={`paper ${isExpanded ? 'paper-expanded' : ''}`}
        >
            <Group position="apart">
                <Group spacing="sm">
                    <Badge className={`status-badge ${entity.isActive ? 'active' : 'inactive'}`}>
                        {entity.isActive ? 'ACTIVE' : 'INACTIVE'}
                    </Badge>
                    <EntityName name={entity.name} />
                </Group>
                <Group spacing="xs">
                    <Group spacing="xs">
                        <Button onClick={onEdit} className="edit-button">
                            Edit
                        </Button>
                        <Button onClick={() => handleDeleteEntity(entity.id ?? '')} className="delete-button">
                            Delete
                        </Button>
                    </Group>
                </Group>
            </Group>
        </Paper>
    );
};

export default EntityItem;