import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { AmortizationScheduleDrillPage } from './amortization-schedule-drill-page'


export function AmortizationLandingPage(tab: { tab: string }) {
  return (
    <PageLayout>
      <AmortizationScheduleDrillPage tab={tab.tab} />
    </PageLayout>
  )
}
