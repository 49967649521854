import { Menu, Modal } from "@mantine/core";
import IonIcon from "@reacticons/ionicons";
import PrimaryDropDown from "../DropDown/DropDown";
import { FacilityParams } from "app/models/facility-params";
import { UpdatedFacilitiesScreen } from "app/views/pages/deals-management/facilities/updated-facilities-screen";
import { useState } from "react";
import { DealParams } from "app/models/deal-params";
import PrimaryInput from "../buttons/PrimaryInput";

interface props {
    facility: FacilityParams
    deal: DealParams
    isCalculationInSync: boolean
    primaryInput?: boolean
    recalculate: () => void
}

export function AmortizationScheduleHeaderActions({ facility, deal, isCalculationInSync, primaryInput, recalculate }: props) {
    const [openEdit, setOpenEdit] = useState(false)

    return (
        <>
            <Menu shadow="md" width={primaryInput ? '250px' : 200} position="bottom" withinPortal>
                <Menu.Target>
                    <div style={{ width: 'fit-content' }}>
                        {primaryInput ?
                            <PrimaryInput w='159px'>
                                Actions
                                <IonIcon name='chevron-down-outline' className='primaryInputChevronDown' />
                            </PrimaryInput>
                            :
                            <PrimaryDropDown>Actions</PrimaryDropDown>
                        }
                    </div>
                </Menu.Target>

                <Menu.Dropdown>
                    {facility?.status?.toLowerCase() !== 'submitted' ? (
                        <Menu.Item
                            icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                            onClick={() => setOpenEdit(true)}
                        >
                            Edit Amortization Schedule
                        </Menu.Item>
                    ) : null}
                    {facility?.status?.toLowerCase() !== 'submitted' &&
                        !isCalculationInSync ? (
                        <Menu.Item icon={<IonIcon className="action-ion-icon" name='settings-outline' />} onClick={recalculate}>
                            Recalculate
                        </Menu.Item>
                    ) : null}
                </Menu.Dropdown>
            </Menu>
            {openEdit && facility?.status !== 'Submitted' && (
                <Modal
                    className="modal-body modal-body-template fit-content-modal"
                    opened={openEdit}
                    onClose={() => setOpenEdit(false)}
                    centered={true}
                    size="77%"
                >
                    <UpdatedFacilitiesScreen
                        deal={deal}
                        amendment={
                            facility?.amendmentType || facility?.status === 'Approved'
                                ? 'amend'
                                : null
                        }
                        facility={facility}
                        onClose={() => setOpenEdit(false)}
                        step={6}
                    />
                </Modal>
            )}
        </>
    )
}