import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { EntitiesDrillPage } from './entities-management-drill-page'

export default function EntitiesManagementPage(tab: { tab: string }) {

  console.log("tab", tab)

  return (
    <PageLayout>
      <PageTitle pageTitle='Entity Management' />
      <EntitiesDrillPage tab={tab.tab} />
    </PageLayout>
  )
}
