import {
    Group,
    Stack,
} from '@mantine/core'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import { ManageEntities } from './manage-entities';
import PrimaryInput from 'app/views/components/buttons/PrimaryInput';
import React, { useState } from 'react'
import MultiPartForm from './multipart-form-modal';



interface props {
    tab: string
}

export function EntitiesDrillPage({ tab }: props) {
    const [openEntity, setOpenEntity] = useState(false)

    const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; pageRender: JSX.Element; } } = {
        entities: { route: `/entitymanagement`, label: 'ENTITIES', active: tab === 'entities' ? true : false, pageRender: <ManageEntities /> },
    }
    return (
        <Stack w="100%">
            <MultiPartForm setOpen={setOpenEntity} open={openEntity} />
            <Group w="100%" position="apart" className='tabsGapsActivity'>
                <Group position="right">
                    <SegmentControlUpdated tabs={tabs} />
                </Group>
                <Group noWrap>
                    <PrimaryInput onClick={() => setOpenEntity(true)} text="Create Entity" />
                </Group>
            </Group>
            {tabs[tab].pageRender}
        </Stack>
    )
}