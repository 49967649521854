import React from 'react';
import { Text } from '@mantine/core';

interface EntityNameProps {
    name: string;
}

const EntityName: React.FC<EntityNameProps> = ({ name }) => (
    <Text className="entity-name">
        {name}
    </Text>
);

export default EntityName;