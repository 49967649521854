import React from 'react';
import { Checkbox } from '@mantine/core';

interface CustomCheckboxProps {
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ label, checked, onChange }) => (
    <Checkbox
        label={label}
        checked={checked}
        onChange={(e) => onChange(e.currentTarget.checked)}
        className="custom-checkbox"
    />
);

export default CustomCheckbox;
