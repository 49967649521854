import { Text } from '@mantine/core';

interface Props {
  label: string;
  divider?: boolean
  children?: React.ReactNode; // Add children to the interface
}

export function TextLabelInput({ label, divider = true, children }: Props) {
  return (
    <>
      {divider ? (
        <>
          <Text className="dataEntryLabel">{label}</Text>
          {typeof children === 'string' ? <Text className="dataEntryInput">{children}</Text> : children}
        </>
      ) : (
        <>
          <Text className="dataEntryLabelND">{label}</Text>
          {typeof children === 'string' ? <Text className="dataEntryInputND">{children}</Text> : children}
        </>
      )}
    </>
  );
}
