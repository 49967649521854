import React, { } from 'react'
import { Stack } from '@mantine/core'

export function TradeEntry() {
  return (
    <Stack>
      Trade Entry
    </Stack>
  )
}
