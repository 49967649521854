import React from 'react';
import { Group, Text, Select } from '@mantine/core';

interface RowsPerPageSelectProps {
    itemsPerPage: number;
    onItemsPerPageChange: (value: string) => void;
}

const RowsPerPageSelect: React.FC<RowsPerPageSelectProps> = ({ itemsPerPage, onItemsPerPageChange }) => {
    return (
        <Group className="rows-per-page-group">
            <Text className="rows-per-page-text">Rows per page</Text>
            <Select
                value={itemsPerPage.toString()}
                onChange={onItemsPerPageChange}
                data={['10', '15', '20', '25']}
                className="rows-per-page-select"
            />
        </Group>
    );
};

export default RowsPerPageSelect;
