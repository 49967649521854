import {
    Group,
    Stack,
    TextInput,
} from '@mantine/core'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import PrimaryInput from 'app/views/components/buttons/PrimaryInput';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EntityProfile from './entity-profile';
import { loadAllEntityProfiles } from 'app/state/ducks/entity-profile/thunks';
import { EntityProfileParams, EntityRole } from 'app/models/entity-profile-params';
import { getEntityProfileLoader } from 'app/state/ducks/entity-profile/selectors';
import { CustomLoaderPage } from 'app/views/components/loader/loader-page';
import { IconSearch } from '@tabler/icons-react';

interface props {
    tab: string
}

export function EntitiesProfileDrillPage({ tab }: props) {
    const dispatch = useDispatch()
    const [formExpanded, setFormExpanded] = useState(false);
    const [expandedEntityId, setExpandedEntityId] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const initialNewEntity: EntityProfileParams = {
        name: '',
        isExternalUser: false,
        isMyCompany: false,
        kycType: '',
        roles: [] as EntityRole[],
        isActive: false
    };

    const [newEntity, setNewEntity] = useState(initialNewEntity);
    const entitiesProfileLoader = useSelector(getEntityProfileLoader)
    const [globalLoading, setGlobalLoading] = useState(false)

    useEffect(() => {
        setGlobalLoading(entitiesProfileLoader)
    }, [entitiesProfileLoader])


    useEffect(() => {
        dispatch(loadAllEntityProfiles())
    }, [])

    const handleOpenFormForCreate = () => {
        setExpandedEntityId(null);
        setNewEntity(initialNewEntity);
        setFormExpanded(true);
    };


    const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; pageRender: JSX.Element; } } = {
        entityprofile: {
            route: `/entityprofile`,
            label: 'AVAILABLE PROFILES',
            active: tab === 'entityprofile' ? true : false,
            pageRender: (
                <EntityProfile
                    formExpanded={formExpanded}
                    setFormExpanded={setFormExpanded}
                    expandedEntityId={expandedEntityId}
                    setExpandedEntityId={setExpandedEntityId}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    newEntity={newEntity}
                    setNewEntity={setNewEntity}
                />
            ),
        },
    }

    return (
        <Stack w="100%">
            <Group w="100%" position="apart" className='tabsGapsActivity'>
                <Group position="right">
                    <SegmentControlUpdated tabs={tabs} />
                </Group>
                <Group noWrap>
                    <TextInput
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.currentTarget.value)}
                        className="search-input"
                        icon={<IconSearch size={16} />}
                    />
                    <PrimaryInput onClick={handleOpenFormForCreate} text="Create Entity Profile" />
                </Group>
            </Group>
            {globalLoading ? <CustomLoaderPage /> :
                tabs[tab].pageRender}
        </Stack>
    )
}
