import {
  Group,
  Stack,
  TextInput,
  Select,
  NumberInput,
  MultiSelect,
  Switch,
  Text,
} from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import {
  LetterOfCreditSubtype,
  LetterOfCreditType,
  Option,
  autoExtendNotificationPeriod,
  currencyOptions,
} from 'app/models/dropdown-options'
import { FacilityParams } from 'app/models/facility-params'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEntities } from 'app/state/ducks/entities/selectors'
import FormWrapper from 'app/views/components/Form/FormWrapper'
import {
  DraftLetterOfCreditParams,
  LetterOfCreditParams,
} from 'app/models/letter-of-credit'
import {
  saveLetterOfCredit,
  submitForApproval,
} from 'app/state/ducks/letters-of-credits/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { getLoansByFacility } from 'app/state/ducks/loans/selectors'
import { getLetterOfCredits } from 'app/state/ducks/letters-of-credits/selectors'
import { stringToDate } from 'app/utils/util-functions'
import { EntityRole } from 'app/models/entity-profile-params'
import { getEntityProfiles } from 'app/state/ducks/entity-profile/selectors'

interface Props {
  form: UseFormReturnType<any, (values: any) => any>
  facility?: FacilityParams
  letterOfCredit?: LetterOfCreditParams
  close: any
}
export default function LetterOfCreditFormPresentation({
  form,
  letterOfCredit,
  facility,
  close,
}: Props) {
  const dispatch = useDispatch()
  const entities = useSelector(getEntities)
  const [availableCurrecncies, setAvailableCurrecncies] = useState<Option[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [borrowerOptions, setBorrowerOptions] = useState<Option[]>([])
  const [issuingBankOptions, setIssuingBankOptions] = useState<Option[]>([])
  const [beneficiaryOptions, setBeneficiaryOptions] = useState<Option[]>([])
  const { data: loans } = useSelector(getLoansByFacility)
  const lettersOfCredits = useSelector(getLetterOfCredits)
  const entityProfiles = useSelector(getEntityProfiles)
  const loansAmount = loans.filter(loan => loan.status == 'Approved').map(loan => loan.amount).reduce((a, b) => Number(a) + Number(b), 0)
  const locsAmount = lettersOfCredits.filter(loc => loc.status == 'Approved').map(loc => loc.amount).reduce((a, b) => Number(a) + Number(b), 0)
  const totalLoanLocsAmount = Number(loansAmount) + Number(locsAmount)

  // set entity options
  useEffect(() => {
    if (!facility || !entities) {
      return
    }
    setBorrowerOptions(
      facility.borrowers
        ? facility.borrowers.map(borrower => {
          const entity = entities.find(entity => entity.id == borrower.id)
          return {
            label: entity?.entityName ?? '',
            value: borrower.id,
          }
        })
        : []
    )

    const locIssuers = facility.subLimits.find(
      sublimit => sublimit.subLimitsType === 'LetterOfCreditIssuance'
    )

    if (!locIssuers) {
      return
    }

    setIssuingBankOptions(
      locIssuers.lenders.map((lenderWithAmount: any) => {
        const entity = entities.find(
          entity => entity.id == lenderWithAmount.lender.id
        )
        return {
          label: entity?.entityName ?? '',
          value: entity?.id ?? '',
        }
      })
    )

    const beneficiariesEntities = entities.filter(entity =>
      entity.entityProfile.some(profile => entityProfiles.find(option => option.id === profile.id)?.roles.includes(EntityRole.LetterOfCreditRole)))


    if (!beneficiariesEntities) {
      return
    }

    setBeneficiaryOptions(
      beneficiariesEntities.map((entity: any) => {
        return {
          label: entity?.entityName ?? '',
          value: entity?.id ?? '',
        }
      })
    )
  }, [entities, letterOfCredit, facility])

  // set Available Currencies
  useEffect(() => {
    if (!facility) {
      return
    }
    setAvailableCurrecncies(
      currencyOptions.filter(option =>
        facility.currencies.includes(option.value)
      )
    )
  }, [facility])

  const createDraftLetterOfCredit = async () => {
    const locParams: DraftLetterOfCreditParams = form.getTransformedValues()
    const response: any = await dispatch(
      saveLetterOfCredit(locParams, letterOfCredit)
    )
    if (response.success) {
      SuccessNotification({
        title: 'Successful Letter of Credit Created',
        message: 'Successful Letter of Credit Created',
      })
    } else {
      ErrorNotification({
        title: 'Failed to Create Letter of Credit',
        message: response.payload ?? 'Please check inputs',
      })
    }
    return response
  }

  const onSubmit = async () => {
    setIsLoading(true)
    const result = await createDraftLetterOfCredit()
    setIsLoading(false)
    if (result) {
      close()
    }
  }

  const saveAndSubmit = async () => {
    setIsLoading(true)
    const response: any = await createDraftLetterOfCredit()
    try {
      if (!response) {
        return
      }
      const result: any = await dispatch(
        submitForApproval(response.payload.data)
      )
      if (result.success) {
        SuccessNotification({
          title: 'Successful Letter of Credit Submitted',
          message: 'Letter of Credit subbmitted succesfuly',
        })
      } else {
        ErrorNotification({
          title: 'Letter of Credit created but not submitted.',
          message: result.payload ?? 'Letter of Credit could not be submitted',
        })
      }
    } finally {
      close()
      setIsLoading(false)
    }
  }

  return (
    <FormWrapper
      title={`${letterOfCredit ? 'Edit' : 'Create a New'} Letter of Credit`}
    >
      <div className="content">
        <form onSubmit={form.onSubmit(() => onSubmit())}>
          <Stack>
            <Group noWrap>
              <TextInput
                readOnly
                label="Facility"
                w="100%"
                value={facility?.name ?? facility?.id ?? ''}
              ></TextInput>
              <Select
                searchable
                clearable
                withAsterisk
                data={LetterOfCreditType}
                label="LC type"
                placeholder="Select LC Type"
                w="100%"
                {...form.getInputProps('letterOfCreditType')}
              />
            </Group>

            {form.values.letterOfCreditType === 'Standby' ? (
              <Group>
                <Select
                  searchable
                  clearable
                  withAsterisk
                  data={LetterOfCreditSubtype}
                  label="Standby Type"
                  placeholder="Select LC Type"
                  w="100%"
                  {...form.getInputProps('letterOfCreditStandbyType')}
                />
              </Group>
            ) : null}

            <Group noWrap>
              <Select
                searchable
                clearable
                withAsterisk
                data={availableCurrecncies}
                label="Select currency"
                placeholder="Currency"
                w="100%"
                {...form.getInputProps('currency')}
              />
              <NumberInput
                withAsterisk
                w="100%"
                label="Amount"
                id="amount"
                placeholder="Enter Amount"
                parser={(value: string) =>
                  value ? value.replace(/\$\s?|(,*)/g, '') : ''
                }
                formatter={(value: any) =>
                  !Number.isNaN(parseFloat(value ?? ''))
                    ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : ''
                }
                {...form.getInputProps('amount')}
              />
            </Group>
            {facility && form.values.amount && Number(form.values.amount) + totalLoanLocsAmount > facility?.amount ?
              <Group noWrap>
                <Text w="100%"></Text>
                <Text w="100%" className='warningText'>Warning: The creation of this Letter of Credit will exceed the Facility Availability</Text>
              </Group>
              : null}
            <Group noWrap>
              <MultiSelect
                searchable
                data={borrowerOptions}
                label="Borrowers"
                w="100%"
                placeholder="Select Borrowers"
                {...form.getInputProps('borrowers')}
              />
              <MultiSelect
                searchable
                data={issuingBankOptions}
                label="Issuing Banks"
                w="100%"
                placeholder="Select Issuing Banks"
                {...form.getInputProps('issuingBanks')}
              />
            </Group>

            <Group noWrap>
              <MultiSelect
                searchable
                data={beneficiaryOptions}
                label="Beneficiaries"
                w="100%"
                placeholder="Select Beneficiaries"
                {...form.getInputProps('beneficiaries')}
              />

              <NumberInput
                w="100%"
                precision={2}
                label="Issuance Fee Rate"
                placeholder="0.00000"
                parser={(value: string) =>
                  value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                }
                formatter={(value: string) =>
                  !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : ''
                }
                {...form.getInputProps('issuingFeeRate')}
              />
            </Group>

            <Group noWrap>
              <CustomDatePicker
                label={'Effective Date'}
                // placeholder="Enter Effective Date"
                date={form.values.effectiveDate}
                setDate={(value: any) => {
                  form.setFieldValue('effectiveDate', value)
                }}
                holidayCalendars={facility?.holidayCalendar ?? []}
              />
              <CustomDatePicker
                label={'Expiration Date'}
                // placeholder="Enter Expiration Date"
                date={form.values.initialExpirationDate}
                setDate={(value: any) =>
                  form.setFieldValue('initialExpirationDate', value)
                }
                holidayCalendars={facility?.holidayCalendar ?? []}
              />
            </Group>
            {facility && form.values.initialExpirationDate && form.values.initialExpirationDate > stringToDate(facility?.maturity) ?
              <Group noWrap>
                <Text w="100%"></Text>
                <Text w="100%" className='topFormErrorText'>Must be before Facility Maturity</Text>
              </Group>
              : null}
            {form.values.currency !== facility?.baseCurrency ? (
              <Group noWrap>
                <NumberInput
                  w="100%"
                  precision={2}
                  label="FX Rate"
                  placeholder="Enter FX Rate"
                  parser={(value: string) =>
                    value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                  }
                  formatter={(value: string) =>
                    !Number.isNaN(parseFloat(value ?? '')) ? value + ' %' : ''
                  }
                  {...form.getInputProps('fxRate')}
                />
              </Group>
            ) : null}

            <Group>
              <Switch
                size="lg"
                labelPosition="left"
                label="Auto Extend (Off/On)"
                checked={form.values.autoExtend}
                {...form.getInputProps('autoExtend')}
              />
            </Group>
            {form.values.autoExtend ? (
              <>
                <Group noWrap>
                  <h5 className="autoExtendDetails">Auto Extend Details</h5>
                </Group>

                <Group noWrap>
                  <Select
                    searchable
                    clearable
                    data={autoExtendNotificationPeriod}
                    label="Notification Period"
                    placeholder="Select Notification Period"
                    w="100%"
                    {...form.getInputProps('autoExtendNotificationPeriod')}
                  />
                  <NumberInput
                    w="100%"
                    precision={0}
                    label="Auto Extend Period"
                    placeholder="Enter Extend Period (days)"
                    parser={(value: string) =>
                      value ? value.replace(/%\s?|\$\s?|(,*)/g, '') : ''
                    }
                    formatter={(value: string) =>
                      !Number.isNaN(parseFloat(value ?? '')) ? value : ''
                    }
                    {...form.getInputProps('autoExtendPeriod')}
                  />
                </Group>

                <Group noWrap>
                  <CustomDatePicker
                    label={'Final Expiration Date'}
                    // placeholder="Enter Final Expiration Date"
                    date={form.values.adjustedExpirationDate}
                    setDate={(value: any) => {
                      form.setFieldValue('adjustedExpirationDate', value)
                    }}
                    holidayCalendars={facility?.holidayCalendar ?? []}
                  />
                </Group>
                {facility && form.values.adjustedExpirationDate && form.values.adjustedExpirationDate > stringToDate(facility?.maturity) ?
                  <Group noWrap>
                    <Text w="100%" className='topFormErrorText'>Must be before Facility Maturity</Text>
                  </Group>
                  : null}
              </>
            ) : null}
            <Group noWrap>
              <PrimaryButton
                loading={isLoading}
                type="submit"
                disabled={!form.isValid()}
                text="Save"
                w="100%"
              />
              <PrimaryButton
                loading={isLoading}
                onClick={() => saveAndSubmit()}
                disabled={!form.isValid()}
                text="Create and Submit"
                w="100%"
              />
            </Group>
          </Stack>
        </form>
      </div>
    </FormWrapper>
  )
}
