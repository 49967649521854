import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getEntityTypes,
  getEntityType,
  addEntityType,
  editEntityType,
  deleteEntityTypes,
  startLoadingEntityTypes,
  stopLoadingEntityTypes,
} from 'app/state/ducks/entity-type/actions'
import { EntityTypeParams } from 'app/models/entity-type-params'

export const saveEntityType =
  (savedEntityType: EntityTypeParams, entityType?: string) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    if (entityType) {
      savedEntityType.id = entityType
      return client
        .put(`${apiServerUrl}/api/entity-types`, savedEntityType)
        .then(entityType => {
          dispatch(editEntityType(entityType))
          return { success: true, payload: entityType }
        })
        .catch(error => {
          return { success: false, payload: error.response.data.error }
        })
    } else {
      return client
        .post(`${apiServerUrl}/api/entity-types`, savedEntityType)
        .then(entityType => {
          dispatch(addEntityType(entityType))
          return { success: true, payload: entityType }
        })
        .catch(error => {
          return { success: false, payload: error.response.data.error }
        })
    }
  }

export const loadAllEntityTypes = () => async (dispatch: Dispatch) => {
  dispatch(startLoadingEntityTypes())
  const apiServerUrl = config.backendUrl
  try {
    const response = await client.get(`${apiServerUrl}/api/entity-types`)
    const entityTypes = response.data
    dispatch(getEntityTypes(entityTypes))
  } catch (e) {
    console.log(e)
    dispatch(getEntityTypes([]))
  } finally {
    dispatch(stopLoadingEntityTypes())
  }
}

export const loadOneEntityType =
  (entityTypeId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(`${apiServerUrl}/api/entity-types/one?id=${entityTypeId}`)
      .then(entityType => {
        return dispatch(getEntityType({ ...entityType.data, status: status }))
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const removeEntityTypes =
  (entityType: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(`${apiServerUrl}/api/entity-types?ids[]=${entityType}`)
      .then(entityTypes => {
        dispatch(deleteEntityTypes(entityTypes.data))
        return { success: true, payload: entityTypes.data }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }
