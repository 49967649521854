import React from 'react'
import IonIcon from '@reacticons/ionicons'

export function TradeEntrySearchIcon() {
  return (
    <div className='tradeEntryCounterpartiesSearch'>
      <IonIcon name="search-outline" className='tradeEntryCounterpartiesSearchIcon' />
    </div>
  )
}
