import IonIcon from '@reacticons/ionicons';
import React from 'react';

interface ChevronIconProps {
    open: boolean;
    onClick: () => void;
}

const ChevronIcon: React.FC<ChevronIconProps> = ({ open, onClick }) => (
    <IonIcon
        name={open ? 'chevron-back-circle-outline' : 'chevron-forward-circle-outline'}
        size="large"
        style={{
            fontSize: '13px',
            position: 'absolute',
            cursor: 'pointer',
            right: '-1.25rem',
            marginTop: '-1.35rem',
        }}
        onClick={onClick}
    />
);

export default ChevronIcon;
