import { CleanedBorrowersData } from './borrowers-wireinstructions-params'
import { WireInstructionParams } from 'app/models/wire-instruction-params';

export function cleanBorrowers(
    borrower: WireInstructionParams,
  ): CleanedBorrowersData[] {
    const cleanedBorrower: CleanedBorrowersData = {
      'Currency': borrower.currency,
      'Bank Aba#': borrower.accountWithInstitutionBankAba,
      'Bank Swift Bic': borrower.accountWithInstitutionSwiftBic,
      'AcctInstitutionName': borrower.accountWithInstitutionName,
      'Customer Name': borrower.beneficiaryCustomerName,
      'Acct#': borrower.beneficiaryAccountNumber,
      'Payment Reference': borrower.paymentReference,
      'Special Instructions': borrower.specialInstructions,
    };
    return [cleanedBorrower];
  }
  