import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Signup from '../app/views/pages/signup'
import { ProtectedRoute } from '../app/views/components/protected-pages-components/+protected-route'
import { NotFoundPage } from '../app/views/pages/error/+not-found-page'
import ProfilePage from '../app/views/pages/profile/+profile-page'
import UsersPage from '../app/views/pages/users/+users-page'
import UserManagementPage from 'app/views/pages/user-management/+user-management-page'
import { ReportingPage } from 'app/views/pages/reports/+reports_page'
import SingleTradeAssignmentPage from 'app/views/pages/trade-closing/single-trade-assignment/+single-trade-assignment-page'
import IndexRateOptionManagementPage from 'app/views/pages/index-rate-options-management/+iro-management-page'
import FeesPage from 'app/views/components/Fees/+manage-fees-facility-page'
import HolidayCalendarsPage from 'app/views/pages/holiday-calendars/+holiday-calendars-page'
import SimulationsPage from 'app/views/pages/simulations/+simulations_page'
import JobManagementPage from 'app/views/pages/job-management/+job-management-page'
import InterestPaymentSchedulePage from 'app/views/components/interest-payment-schedule/+manage-interest-payment-page'
import PIKSchedulerPage from 'app/views/components/pik-scheduler/+manage-pik-page'
import NoticePreview from 'app/views/pages/release-queue/notices/preview/+notice-preview-page'
import { MyWorkLandingPage } from 'app/views/pages/mywork/+mywork_landing_page'
import { PositionsLandingPage } from 'app/views/pages/positions/+positions_landing_page'
import { EntityLandingPage } from 'app/views/pages/accounts-management/entities/entity-details/+entity_landing_page'
import { DealManagmentPage } from 'app/views/pages/deals-management/deals-management-landing-components/+deals-management-page'
import { LockedDealManagmentPage } from 'app/views/pages/deals-management/deals-management-landing-components/+locked-deals-management-page'
import { PricingGridPage } from 'app/views/pages/deals-management/pricing-grid/+pricing-grid-page'
import { DealFeesPage } from 'app/views/pages/deals-management/deal-fees/+deal-fees-page'
import BorrowersPage from 'app/views/pages/deals-management/facilities/facility-details/+borrowers-page'
import BorrowersPageDeal from 'app/views/pages/deals-management/deals/deal-details/+borrowers-deal-page'
import GuarantorsPageDeal from 'app/views/pages/deals-management/deals/deal-details/+guarantors-deal-page'
import { ReleaseQueueLandingPage } from 'app/views/pages/release-queue/release-queue-landing-pages/+release_queue_landing_page'
import { LockedReleaseQueueLandingPage } from 'app/views/pages/release-queue/release-queue-landing-pages/+locked_release_queue_landing_page'
import { ServicingLandingPage } from 'app/views/pages/servicing/+servicing_landing_page'
import { TradeClosingLandingPage } from 'app/views/pages/trade-closing/+trade-closing-landing-page'
import { DealLandingPage } from 'app/views/pages/deals-management/deals/deal-details/+deal-landing-page'
import { FacilityLandingPage } from 'app/views/pages/deals-management/facilities/facility-details/+facility-landing-page'
import EntitiesManagementPage from 'app/views/pages/accounts-management/entities/+entities-management-page'
import { LenderAllocationsLandingPage } from 'app/views/pages/deals-management/lender-allocations/+lender-allocations-landing-page'
import { TradeEntryLandingPage } from 'app/views/pages/trade-entry/+trade-entry-landing-page'
import EntitiesProfilePage from 'app/views/pages/accounts-management/entities/entity-profile/+entities-profile-page'
import { AmortizationLandingPage } from 'app/views/components/amortization-scheduler/+amortization-schedule-landing-page'
import EntitiesTypePage from 'app/views/pages/accounts-management/entities/entity-type/+entities-type-page'

export function RightPedalRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/mywork" replace={true} />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/profile"
          element={<ProtectedRoute component={ProfilePage} />}
        />
        <Route
          path="/users"
          element={<ProtectedRoute component={UsersPage} />}
        />
        <Route
          path="/mywork"
          element={<ProtectedRoute component={MyWorkLandingPage} tab="todo" />}
        />
        <Route
          path="/mywork/todo"
          element={<ProtectedRoute component={MyWorkLandingPage} tab="todo" />}
        />
        <Route
          path="/mywork/payments"
          element={<ProtectedRoute component={MyWorkLandingPage} tab="payments" />}
        />
        <Route
          path="/mywork/unscheduled"
          element={<ProtectedRoute component={MyWorkLandingPage} tab="unscheduled" />}
        />
        <Route
          path="/mywork/scheduled"
          element={<ProtectedRoute component={MyWorkLandingPage} tab="scheduled" />}
        />
        <Route
          path="/entityprofile"
          element={<ProtectedRoute component={EntitiesProfilePage} tab="entityprofile" />}
        />
        <Route
          path="/entitytype"
          element={<ProtectedRoute component={EntitiesTypePage} tab="entitytype" />}
        />
        <Route
          path="/entitymanagement"
          element={<ProtectedRoute component={EntitiesManagementPage} tab='entities' />}
        />
        <Route
          path="/entitymanagement/entity/:entityId/:status"
          element={<ProtectedRoute component={EntityLandingPage} tab="entityBase" />}
        />
        <Route
          path="/entitymanagement/contacts/:entityId/:status"
          element={<ProtectedRoute component={EntityLandingPage} tab="entityContacts" />}
        />
        <Route
          path="/entitymanagement/wireinstructions/:entityId/:status"
          element={<ProtectedRoute component={EntityLandingPage} tab="entityWI" />}
        />
        <Route
          path="/entitymanagement/positions/:entityId/:status"
          element={<ProtectedRoute component={EntityLandingPage} tab="entityPositions" />}
        />
        <Route
          path="/positions"
          element={<ProtectedRoute component={PositionsLandingPage} tab="positionsDeal" />}
        />
        <Route
          path="/positions/deal"
          element={<ProtectedRoute component={PositionsLandingPage} tab="positionsDeal" />}
        />
        <Route
          path="/positions/facility"
          element={<ProtectedRoute component={PositionsLandingPage} tab="positionsFacility" />}
        />
        <Route
          path="/positions/entity"
          element={<ProtectedRoute component={PositionsLandingPage} tab="positionsEntity" />}
        />
        <Route
          path="/dealmanagement/deal/:dealId/:status"
          element={<ProtectedRoute component={DealLandingPage} tab='dealDetails' />}
        />
        <Route
          path="/dealmanagement/deal/:dealId/:status/pricing-grid"
          element={<ProtectedRoute component={PricingGridPage} />}
        />
        <Route
          path="/dealmanagement/deal/:dealId/:status/fees/:feeType"
          element={<ProtectedRoute component={DealFeesPage} />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/:status"
          element={<ProtectedRoute component={FacilityLandingPage} tab='facilityDetails' />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/:status/loans"
          element={<ProtectedRoute component={FacilityLandingPage} tab='facilityDetailsLoans' />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/:status/locs"
          element={<ProtectedRoute component={FacilityLandingPage} tab='facilityDetailsLoCs' />}
        />

        <Route
          path="/dealmanagement/:contractType/:contractId/:status/lenders"
          element={<ProtectedRoute component={LenderAllocationsLandingPage} tab="lenderAllocationsFund" />}
        />
        <Route
          path="/dealmanagement/:contractType/:contractId/:status/lenders/fund-manager"
          element={<ProtectedRoute component={LenderAllocationsLandingPage} tab="lenderAllocationsFundManager" />}
        />

        <Route
          path="/dealmanagement/facilities/:facilityId/:status/fees"
          element={<ProtectedRoute component={FeesPage} />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/:status/fees/:feeType"
          element={<ProtectedRoute component={FeesPage} />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/:status/amortization-schedule"
          element={<ProtectedRoute component={AmortizationLandingPage} tab="amortizationScheduleCurrent" />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/:status/amortization-schedule/history"
          element={<ProtectedRoute component={AmortizationLandingPage} tab="amortizationScheduleHistory" />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/:status/:loanId/:loanStatus/interest-payment-schedule"
          element={<ProtectedRoute component={InterestPaymentSchedulePage} />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/:status/:loanId/:loanStatus/pik-schedule"
          element={<ProtectedRoute component={PIKSchedulerPage} />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/borrowers"
          element={<ProtectedRoute component={BorrowersPage} />}
        />
        <Route
          path="/dealmanagement/deal/:dealId/borrowers"
          element={<ProtectedRoute component={BorrowersPageDeal} />}
        />
        <Route
          path="/dealmanagement/deal/:dealId/guarantors"
          element={<ProtectedRoute component={GuarantorsPageDeal} />}
        />
        <Route
          path="/dealmanagement"
          element={<ProtectedRoute component={DealManagmentPage} tab="deals" />}
        />
        <Route
          path="/dealmanagement/facilities"
          element={<ProtectedRoute component={DealManagmentPage} tab="facilities" />}
        />
        <Route
          path="/dealmanagement/terminated"
          element={<ProtectedRoute component={LockedDealManagmentPage} tab="dealsLocked" />}
        />
        <Route
          path="/dealmanagement/facilities/terminated"
          element={<ProtectedRoute component={LockedDealManagmentPage} tab="facilitiesLocked" />}
        />
        <Route
          path="/releasequeue"
          element={<ProtectedRoute component={ReleaseQueueLandingPage} tab="releaseQueuePayments" />}
        />
        <Route
          path="/releasequeue/notices"
          element={<ProtectedRoute component={ReleaseQueueLandingPage} tab="releaseQueueNotices" />}
        />
        <Route
          path="/releasequeue/archived"
          element={<ProtectedRoute component={LockedReleaseQueueLandingPage} tab="lockedReleaseQueuePayments" />}
        />
        <Route
          path="/releasequeue/notices/archived"
          element={<ProtectedRoute component={LockedReleaseQueueLandingPage} tab="lockedReleaseQueueNotices" />}
        />
        <Route
          path="/releasequeue/notice-preview/:id"
          element={<ProtectedRoute component={NoticePreview} />}
        />
        <Route
          path="/assetservicing"
          element={<ProtectedRoute component={ServicingLandingPage} tab="all" />}
        />
        <Route
          path="/assetservicing/scheduled"
          element={<ProtectedRoute component={ServicingLandingPage} tab="scheduled" />}
        />
        <Route
          path="/assetservicing/unscheduled"
          element={<ProtectedRoute component={ServicingLandingPage} tab="unscheduled" />}
        />
        <Route
          path="/tradeentry"
          element={<ProtectedRoute component={TradeEntryLandingPage} tab='tradeEntry' />}
        />
        <Route
          path="/tradeentry/create"
          element={<ProtectedRoute component={TradeEntryLandingPage} tab='tradeEntryCreation' />}
        />
        <Route
          path="/tradeclosing"
          element={<ProtectedRoute component={TradeClosingLandingPage} tab='tradeClosing' />}
        />
        <Route
          path="/tradeclosing/parent"
          element={<ProtectedRoute component={TradeClosingLandingPage} tab='tradeClosingParent' />}
        />
        <Route
          path="/tradeclosing/subtrade"
          element={<ProtectedRoute component={TradeClosingLandingPage} tab='tradeClosingSubTrade' />}
        />
        <Route
          path="/tradeclosing/new-assignment"
          element={<ProtectedRoute component={SingleTradeAssignmentPage} />}
        />
        <Route
          path="/tradeclosing/assignment/:status/:id"
          element={<ProtectedRoute component={SingleTradeAssignmentPage} />}
        />
        <Route
          path="/reports"
          element={<ProtectedRoute component={ReportingPage} />}
        />
        <Route
          path="/usermanagement"
          element={<ProtectedRoute component={UserManagementPage} />}
        />
        <Route
          path="/indexrateoptions"
          element={<ProtectedRoute component={IndexRateOptionManagementPage} />}
        />
        <Route
          path="/holidaycalendars"
          element={<ProtectedRoute component={HolidayCalendarsPage} />}
        />
        <Route
          path="/simulations"
          element={<ProtectedRoute component={SimulationsPage} />}
        />
        <Route
          path="/jobmanagement"
          element={<ProtectedRoute component={JobManagementPage} />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  )
}