import * as types from './types'

export const getEntityProfiles = (payload: any) => ({
  type: types.GET_ENTITY_PROFILES,
  payload,
})

export const getEntityProfile = (payload: any) => ({
  type: types.GET_ENTITY_PROFILE,
  payload,
})

export const addEntityProfile = (payload: any) => ({
  type: types.ADD_ENTITY_PROFILE,
  payload,
})

export const editEntityProfile = (payload: any) => ({
  type: types.EDIT_ENTITY_PROFILE,
  payload,
})

export const deleteEntityProfiles = (payload: any) => ({
  type: types.DELETE_ENTITY_PROFILES,
  payload,
})

export const startLoadingEntityProfiles = () => ({
  type: types.START_LOADING_ENTITY_PROFILES,
  payload: null,
})

export const stopLoadingEntityProfiles = () => ({
  type: types.STOP_LOADING_ENTITY_PROFILES,
  payload: null,
})
