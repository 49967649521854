import * as types from './types'

export const getEntityTypes = (payload: any) => ({
  type: types.GET_ENTITY_TYPES,
  payload,
})

export const getEntityType = (payload: any) => ({
  type: types.GET_ENTITY_TYPE,
  payload,
})

export const addEntityType = (payload: any) => ({
  type: types.ADD_ENTITY_TYPE,
  payload,
})

export const editEntityType = (payload: any) => ({
  type: types.EDIT_ENTITY_TYPE,
  payload,
})

export const deleteEntityTypes = (payload: any) => ({
  type: types.DELETE_ENTITY_TYPES,
  payload,
})

export const startLoadingEntityTypes = () => ({
  type: types.START_LOADING_ENTITY_TYPES,
  payload: null,
})

export const stopLoadingEntityTypes = () => ({
  type: types.STOP_LOADING_ENTITY_TYPES,
  payload: null,
})
