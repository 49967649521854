import React, { } from 'react'
import { Group, Stack } from '@mantine/core'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import PrimaryInput from 'app/views/components/buttons/PrimaryInput'
import { TradeEntry } from './trade-entry'
import { TradeEntryFormPresentation } from './trade-entry-form-presentation'


interface props {
  tab: string
}

export function TradeEntryDrillPage({ tab }: props) {

  const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; pageRender: React.ReactNode; } } = {
    tradeEntry: { route: '/tradeentry', label: 'TRADES', active: tab === 'tradeEntry' ? true : false, pageRender: <TradeEntry /> },
    tradeEntryCreation: { route: '/tradeentry/create', label: 'CREATE TRADE', active: tab === 'tradeEntryCreation' ? true : false, pageRender: <TradeEntryFormPresentation /> },
  }

  return (
    <Stack>
      <Group w="100%" position="apart" className='tabsGapsActivity'>
        <Group position="right">
          <SegmentControlUpdated tabs={tabs} />
        </Group>
        <Group noWrap>
          {/*Info: This should be an actions dropdown, but I am not sure what should be there */}
          <PrimaryInput onClick={() => { console.log('Actions') }} text="Actions" />
        </Group>
      </Group>
      {tabs[tab].pageRender}
    </Stack>
  )
}
