import { ServicingParams } from 'app/models/servicing-params'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { KpiProps } from 'app/views/components/KPI/KPI'
import { useSelector } from 'react-redux'
import { useKPIFilters } from 'app/views/components/KPI/KPI-filters'
import { useEffect } from 'react'

export const getKPIData = (
  data: ServicingParams[],
  setData: React.Dispatch<React.SetStateAction<ServicingParams[]>>,
): KpiProps[] => {
  const businessDate = useSelector(getBusinessDate)

  const filterFunctions: Record<
    string,
    (servicing: ServicingParams) => boolean
  > = {
    'Activity Today': (servicing: ServicingParams) =>
      businessDate.toLocaleDateString() === servicing.effectiveDate,
    Pending: (servicing: ServicingParams) =>
      ['Pending Submit', 'Pending Review'].includes(servicing.stage),
    'Pending Approval': (servicing: ServicingParams) =>
      ['Pending Approval', 'Submitted'].includes(servicing.stage),
    'New Borrowings': (servicing: ServicingParams) =>
      ['New Borrowing'].includes(servicing.transaction),
    'Activity Complete': (servicing: ServicingParams) =>
      ['Released'].includes(servicing.notices),
  }

  const { applyFilter, appliedFilters } = useKPIFilters({
    data,
    filterFunctions,
    setData,
  })

  useEffect(() => {
    if (appliedFilters.size === 0) return;
    applyFilter(null);
  }, [data]);

  if (!data) return []

  const activityToday = data.filter(
    row => businessDate.toLocaleDateString() === row.effectiveDate,
  )
  const pending = data.filter(row =>
    ['Pending Submit', 'Pending Review'].includes(row.stage),
  )
  const pendingApproval = data.filter(row =>
    ['Pending Approval', 'Submitted'].includes(row.stage),
  )
  const newBorrowings = data.filter(row =>
    ['New Borrowing'].includes(row.transaction),
  )
  const activityComplete = data.filter(row =>
    ['Released'].includes(row.notices),
  )

  return [
    {
      title: 'Activity Today',
      amount: activityToday.length,
      activeFilter: appliedFilters.has('Activity Today'),
      event: () => applyFilter('Activity Today'),
    },
    {
      title: 'Pending',
      amount: pending.length,
      activeFilter: appliedFilters.has('Pending'),
      event: () => applyFilter('Pending'),
    },
    {
      title: 'Pending Approval',
      amount: pendingApproval.length,
      activeFilter: appliedFilters.has('Pending Approval'),
      event: () => applyFilter('Pending Approval'),
    },
    {
      title: 'New Borrowings',
      amount: newBorrowings.length,
      activeFilter: appliedFilters.has('New Borrowings'),
      event: () => applyFilter('New Borrowings'),
    },
    {
      title: 'Activity Complete',
      amount: activityComplete.length,
      activeFilter: appliedFilters.has('Activity Complete'),
      event: () => applyFilter('Activity Complete'),
    },
  ]
}
