import {
  Group,
  MultiSelect,
  NumberInput,
  Select,
  SelectItem,
  Stack,
  TextInput,
} from '@mantine/core'
import { useForm, isNotEmpty } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { EntityParams } from 'app/models/entity-params'
import {
  Option,
  currencyOptions,
  lenderEligibilityCriteriaOptions,
  pikOptions,
  staticFacilityTypes,
  strBooleanOptions,
} from 'app/models/dropdown-options'
import moment from 'moment'
import { saveFacility } from 'app/state/ducks/facilities/thunks'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import { DraftFacilityParams, FacilityParams } from 'app/models/facility-params'
import config from 'app/config/config'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import { getWireInstructions } from 'app/state/ducks/wire-instructions/selectors'
import { DealParams } from 'app/models/deal-params'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { getContacts } from 'app/state/ducks/contacts/selectors'
import { getDeals } from 'app/state/ducks/deals/selectors'
import { ContactParams } from 'app/models/contact-params'
import { StableKey } from 'app/models/common-types'
import FormWrapper from 'app/views/components/Form/FormWrapper'
import { formatDateToUTC, formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'
import { showAmortizationSchedule } from './updated-facilities-screen'
import {
  loadNullFacilityAmendment,
  loadOneFacilityAmendment,
  saveFacilityAmendment,
} from 'app/state/ducks/facility-amendments/thunks'
import {
  getFacilityAmendment,
  getFacilityAmendments,
} from 'app/state/ducks/facility-amendments/selectors'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { loadFeesByFacility } from 'app/state/ducks/fees/thunks'
import { loadSchedulerAmortizationByFacility } from 'app/state/ducks/amortization/thunks'
import { getCalendars } from 'app/state/ducks/holiday-calendars/selectors'
import { HolidayCalendarParams } from 'app/models/holiday-calendar-params'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { getFacilities } from 'app/state/ducks/facilities/selectors'
import { getEntityProfiles } from 'app/state/ducks/entity-profile/selectors'
import { EntityRole } from 'app/models/entity-profile-params'

export interface Props {
  deal?: DealParams
  facility?: DraftFacilityParams
  setSaveButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>
  setDisabledSteps: React.Dispatch<React.SetStateAction<number[]>>
  setFacility: React.Dispatch<React.SetStateAction<any>>
  dealStepper?: boolean
  amendment?: string | null
}

export interface ChildFacilityRef {
  handleClick: () => void
}

const UpdatedFacilitiesModal: React.ForwardRefRenderFunction<
  ChildFacilityRef,
  Props
> = (
  {
    deal,
    facility,
    amendment,
    setSaveButtonDisabled,
    setDisabledSteps,
    setFacility,
    dealStepper,
  },
  ref
) => {
    const entities: EntityParams[] = useSelector(getEntities)
    const entityProfiles = useSelector(getEntityProfiles)
    const contacts: ContactParams[] = useSelector(getContacts)
    const facilities = useSelector(getFacilities)
    const deals: DealParams[] = useSelector(getDeals)
    const holidayCalendars: HolidayCalendarParams[] = useSelector(getCalendars)
    const businessDate = useSelector(getBusinessDate)
    const holidayCalendarsOptions = holidayCalendars?.filter(calendar => calendar.status === 'Active').map(calendar => ({ label: calendar.name, value: calendar.id ?? '' }))

    const borrowersToDisplay = (ents: EntityParams[]) => {
      return ents?.filter(entity =>
        entity.entityProfile.some(profile => entityProfiles.find(option => option.id === profile.id)?.roles.includes(EntityRole.BorrowerRole)))
        .map(entity => ({ label: entity.entityName, value: entity.id ?? '' }))
    }
    const dealBorrowersIds = deal?.borrowers.map(borrower => borrower.id) ?? []
    const borrowerEnts = entities?.filter(entity => dealBorrowersIds.includes(entity?.id ?? ''))
    const dealBorrowers = borrowersToDisplay(borrowerEnts)
    const allBorrowers = borrowersToDisplay(entities) ?? []

    const borrowersDropdownOptions: SelectItem[] = deal ? dealBorrowers : allBorrowers;
    const [borrowerDropdown, setBorrowerDropdown] = useState<SelectItem[]>(borrowersDropdownOptions)

    const dealsOptions = deals
      ? deals.map(deal => ({ label: deal.dealName, value: deal.id ?? '' }))
      : []

    const wireInstructionList: any = useSelector(getWireInstructions)
    const [borrowerWireInstructionsArray, setBorrowerWireInstructionsArray] = useState<any>(facility?.borrowers ? getWireInstructionOptions('borrower', facility?.borrowers?.map(e => e.id)) : [])
    const [adminWireInstructionsArray, setAdminWireInstructionsArray] = useState<any>(facility?.adminEntity?.id ? getWireInstructionOptions('admin', [facility?.adminEntity?.id]) : [])
    const [selectedDeal, setSelectedDeal] = useState<DealParams | undefined>(deal)
    const dispatch = useDispatch()
    const facilityAmendments: FacilityParams[] = useSelector(
      getFacilityAmendments
    )
    const amended_facility =
      facilityAmendments?.find(amendment => amendment.id === facility?.id) ?? null
    const facilityAmendment: FacilityParams = useSelector(getFacilityAmendment)

    const initDealBorrowers = deal?.borrowers.length === 1 ? deal?.borrowers?.map((b: any) => {
      return b.id
    }) : []


    const handleBorrowersChange = (e: string[]) => {
      if (e.length <= 0) {
        form.setFieldValue('borrowers', [])
        form.setFieldValue('borrowerPaymentInstructions', [])
      } else {
        form.setFieldValue('borrowers', e)
      }
      updateDynamicName('borrower', e[0])
      loadWireInstructionOptions('borrowers', e)
    }

    const handleAdminChange = (e: string | null) => {
      if (!e) return
      form.setFieldValue('opsAdmin', '')
      form.setFieldValue('adminEntity', e)
      if (e.length <= 0) {
        form.setFieldValue('adminAgentPaymentInstructions', [])
      }
      loadWireInstructionOptions('admin', [e])
    }

    const handleFacilityTypeChange = (e: string) => {
      form.setFieldValue('facilityType', e)
      if (e === 'TL' || e === 'TLA' || e === 'TLB' || e === 'TLC') {
        form.setFieldValue('isSublimit', 'false')
      }
      form.setFieldValue('facilityType', e)
      updateDynamicName('type', e)
    }

    const handleAmountChange = (e: number) => {
      form.setFieldValue('amount', e)
      form.validateField('amount')
      updateDynamicName('amount', e)
    }

    const handleCurrencyChange = (e: string) => {
      form.setFieldValue('baseCurrency', e)
      updateDynamicName('currency', e)
    }

    const updateDynamicName = (
      key: keyof typeof form.values.dynamicNameObj,
      value: string | any
    ) => {
      const dynaobj = form.values.dynamicNameObj
      if (key == 'borrower') {
        const entityName =
          entities.find(entity => entity.id === cleanUUID(value))?.entityName ??
          ''
        dynaobj['borrower'] = entityName
      } else {
        dynaobj[key] = value
      }
      let formattedAmount = ''
      if (Number(dynaobj.amount) > 0) {
        formattedAmount = formatNumberToCurrency(
          Number(dynaobj.amount),
          dynaobj.currency ?? 'USD',
          1,
          1,
          'compact'
        )
      }
      const exportName =
        dynaobj.borrower +
        ' ' +
        (dynaobj.type ?? '') +
        ' ' +
        formattedAmount +
        ' ' +
        moment(dynaobj.date).format('YYYY/MM/DD').toString()
      form.setFieldValue('dynamicNameObj', dynaobj)
      form.setFieldValue('name', exportName)
    }

    const handleDealChange = (dealId: string) => {
      form.setFieldValue('dealId', dealId)
      const curDeal = deals.find(deal => deal.id == dealId)
      setSelectedDeal(curDeal)
      const dealBorrowersIds = curDeal?.borrowers.map(borrower => borrower.id) ?? []
      const borrowerEnts = entities?.filter(entity => dealBorrowersIds.includes(entity?.id ?? ''))
      const dealBorrowers = borrowersToDisplay(borrowerEnts)
      setBorrowerDropdown(dealBorrowers)
      const starterBorrowers = curDeal?.borrowers.length === 1 ? curDeal?.borrowers?.map((b: any) => {
        return b.id
      }) : []
      form.setFieldValue('borrowers', starterBorrowers)
      if (starterBorrowers.length > 0) {
        updateDynamicName('borrower', starterBorrowers[0])
        loadWireInstructionOptions('borrowers', starterBorrowers)
      }
    }

    function getWireInstructionOptions(type: string, value: string[]) {
      if (value) {
        const arr: any = []
        value.map((id: string) => {
          wireInstructionList.map(
            (b: {
              id: string
              admin: string
              entity: { id: string }
              currency: string
              beneficiaryAccountNumber: string
            }) => {
              if (b.entity.id.replaceAll('"', '') == id) {
                const option: Option = {
                  label: b.currency + '_' + b.beneficiaryAccountNumber,
                  value: b.id,
                }
                arr.push(option)
              }
            }
          )
        })
        return arr
      }
      return []
    }

    function loadWireInstructionOptions(type: string, value: string[]) {
      const getWireInstructions = getWireInstructionOptions(type, value)
      if (value) {
        if (type === 'admin') {
          setAdminWireInstructionsArray(getWireInstructions)
        } else {
          setBorrowerWireInstructionsArray(getWireInstructions)
        }
      }
    }

    const form = useForm({
      initialValues: {
        id: facility?.id ?? undefined,
        dynamicNameObj: {
          borrower:
            entities && facility && facility.borrowers
              ? entities.find(
                entity => entity.id === cleanUUID(facility?.borrowers[0]?.id)
              )?.entityName
              : entities && selectedDeal
                ? entities.find(entity => entity.id === cleanUUID(selectedDeal.borrowers[0].id))?.entityName
                : '',
          type: facility?.facilityType ?? '',
          amount: facility?.amount ?? '',
          date: facility?.startDate ? stringToDate(facility?.startDate) : businessDate,
          currency: facility?.baseCurrency ?? 'USD',
        },
        name: facility?.name ?? '',
        dealId: selectedDeal
          ? facility?.dealId?.id ?? selectedDeal.id
          : facility?.dealId?.id ?? '',
        amount: Number(facility?.amount) ?? 0,
        utilizedAmount: facility?.utilizedAmount ?? 0,
        amortizationScheduleId: facility?.amortizationScheduleId ?? null,
        accrualFee: facility?.accrualFee ?? [],
        holidayCalendar: facility?.holidayCalendar?.map((b: any) => {
          return b.id
        }) ?? deal?.holidayCalendar?.map((b: any) => {
          return b.id
        }) ?? [],
        adminEntity: cleanUUID(facility?.adminEntity?.id ?? ''),
        opsAdmin: cleanUUID(facility?.opsAdmin?.id ?? ''),
        currencies: facility?.currencies ? facility.currencies : [],
        baseCurrency: facility?.baseCurrency ?? '',
        borrowers:
          facility?.borrowers?.map((b: any) => {
            return b.id
          }) ?? initDealBorrowers ?? [],
        isSublimit: facility?.isSublimit == true ? 'true' : 'false',
        lenderEligibilityCriteria: facility?.lenderEligibilityCriteria ?? [],
        facilityType: facility?.facilityType ?? '',
        pikOption: facility?.pikOption ?? '',
        startDate: facility?.startDate
          ? stringToDate(facility?.startDate)
          : businessDate,
        maturity: facility?.maturity
          ? stringToDate(facility?.maturity)
          : businessDate,
        cusip: facility?.cusip,
        isin: facility?.isin,
        lxId: facility?.lxId ?? '',
        bbgId: facility?.bbgId ?? '',
        borrowerPaymentInstructions:
          facility?.borrowerPaymentInstructions?.map((b: any) =>
            cleanUUID(b.id)
          ) ?? [],
        adminAgentPaymentInstructions:
          facility?.adminAgentPaymentInstructions?.map((b: any) =>
            cleanUUID(b.id)
          ) ?? [],
        subLimits: facility?.subLimits ?? [],
        iroValues: facility?.iroValues ?? [],
        restrictLoanDate: facility?.restrictLoanDate ?? false,
        amendmentDate: amendment
          ? facility?.amendmentDate ? stringToDate(facility.amendmentDate) : businessDate
          : undefined,
        amendmentLenders: facility?.amendmentLenders ?? [],
        amendmentFees: facility?.amendmentFees ?? [],
        amendmentAmortization: facility?.amendmentAmortization ?? null,
        nextAmortizationPaymentData: facility?.nextAmortizationPaymentData ?? null,
        pikScheduler: facility?.pikScheduler ?? null,
      },

      validate: {
        name: isNotEmpty('Facility Name cannot be empty'),
        facilityType: isNotEmpty('Facility Type cannot be empty'),
        dealId: isNotEmpty('Deal cannot be empty'),
        amount: (value: number) => validateAvailability(value),
        lxId: value =>
          value == '' || value.length === 8 ? null : 'Invalid amount',
        bbgId: value =>
          value == '' || value.length === 9 ? null : 'Invalid amount',
      },
      validateInputOnBlur: true,
    })

    const validateAvailability = (value: number) => {
      if (selectedDeal) {
        const totalFacilityAmount = facilities.filter(fact => (fact.dealId.id === selectedDeal.id && fact.id != facility?.id)).reduce((acc, cur) => Number(acc) + Number(cur.amount), 0)
        if (totalFacilityAmount + value > Number(selectedDeal.amount)) {
          return 'Deal Availability Exceeded'
        }
      }
      if (value < 0) {
        return 'Amount has to be greater than 0'
      }
      return null
    }


    React.useEffect(() => {
      if (facility && facility?.id && amended_facility) {
        dispatch(loadOneFacilityAmendment(facility?.id, 'Draft'))
      } else if (amended_facility) {
        dispatch(loadNullFacilityAmendment())
      }
      if (borrowerWireInstructionsArray.length === 0 && initDealBorrowers.length > 0) {
        loadWireInstructionOptions('borrowers', initDealBorrowers)
      }
    }, [amended_facility, facility?.id, facility])

    React.useEffect(() => {
      setSaveButtonDisabled(!form.isValid())
    }, [form.isValid()])

    const handleClick = async () => {
      await onSubmit(form.values)
    }

    // Expose the handleClick method to the parent component
    useImperativeHandle(ref, () => ({
      handleClick,
    }))

    const onSubmit = async (facilityValues: any) => {
      try {
        const deal = selectedDeal
          ? selectedDeal
          : deals.find(deal => deal.id == facilityValues.dealId) ?? null
        const borrowers = entities
          .filter(entity => facilityValues.borrowers.includes(entity.id))
          .map(entity => ({ id: entity.id, admin: entity.maker }))
        const adminEntity = entities.find(
          entity => facilityValues.adminEntity == entity.id
        )
        const opsAdmin = contacts.find(
          contact => contact.id === facilityValues.opsAdmin
        )
        const borrowerPaymentInstructions: StableKey[] = wireInstructionList
          .filter((wireInstruction: any) =>
            facilityValues.borrowerPaymentInstructions.includes(
              wireInstruction.id
            )
          )
          .map((wireInstruction: any) => ({
            id: wireInstruction.id,
            admin: wireInstruction.public,
          }))

        const adminAgentPaymentInstructions: StableKey[] = wireInstructionList
          .filter((wireInstruction: any) =>
            facilityValues.adminAgentPaymentInstructions.includes(
              wireInstruction.id
            )
          )
          .map((wireInstruction: any) => ({
            id: wireInstruction.id,
            admin: wireInstruction.public,
          }))

        if (!deal) {
          ErrorNotification({
            title: 'Facility Submission Failed',
            message: 'Deal is not selected',
          })
          console.log('Facility Edit Failed Due to Lack of Deal')
          return
        }
        const facilityparams: DraftFacilityParams = {
          ...facilityValues,
          dealId: { id: deal.id, admin: deal.accountManagementAdmin },
          borrowers,
          borrowerPaymentInstructions,
          adminAgentPaymentInstructions,
          baseCurrency:
            facilityValues.baseCurrency !== ''
              ? facilityValues.baseCurrency
              : null,
          pikOption:
            facilityValues.pikOption !== '' ? facilityValues.pikOption : null,
          opsAdmin: opsAdmin ? { id: opsAdmin.id, admin: opsAdmin.maker } : null,
          adminEntity: adminEntity
            ? { id: adminEntity.id, admin: adminEntity.maker }
            : null,
          accrualFee: facility?.accrualFee ?? [],
          isSublimit: facilityValues.isSublimit == 'false' ? false : true,
          maturity: formatDateToUTC(facilityValues.maturity),
          startDate: formatDateToUTC(facilityValues.startDate),
          restrictLoanDate: false,
          subLimits:
            facilityValues.isSublimit == 'false' ? [] : facility?.subLimits ?? [],
          holidayCalendar: facilityValues.holidayCalendar.map((id: string) => {
            const curCal = holidayCalendars.find(({ id }) => id === id)
            return ({ id, admin: curCal?.public })
          }),
          pikScheduler: facility?.pikOption !== "PIK_Scheduled" ? null : facility?.pikScheduler,
          facilityPricingGrid: facility?.facilityPricingGrid ?? [],
          amendmentDate: amendment
            ? formatDateToUTC(facilityValues.amendmentDate)
            : undefined,
          hasError: false,
          hasSuccessfulEntitySave: false,
          ErrorMessage: '',
          customer_token: config.company.toUpperCase(),
        }
        let response: any = null
        if (amendment) {
          response = await dispatch(
            saveFacilityAmendment(facilityparams, facilityAmendment)
          )
          if (response?.success === false) {
            ErrorNotification({
              title: ' Facility Amendment Failed',
              message:
                response?.payload || 'Check Lender Allocations and try again',
            })
            return
          }
          // dispatch(setOneFacility(response.payload));
          setFacility(response.payload)
        } else {
          response = await dispatch(
            saveFacility(facilityparams, !!facilityValues.id)
          )
          // dispatch(setOneFacility(response.payload));
          setFacility(response.payload)
        }
        dispatch(loadFeesByFacility(response.payload.id))
        dispatch(loadSchedulerAmortizationByFacility(response.payload.id))
        const newDisabledSteps =
          facilityparams.isSublimit === false ? (dealStepper ? [5] : [2]) : []
        const disableAmortization = showAmortizationSchedule(facilityValues)
          ? []
          : dealStepper
            ? [9]
            : [6]
        const disablePik = facilityValues.pikOption === 'PIK_Scheduled'
          ? []
          : dealStepper
            ? [10]
            : [7]
        const disablePricingGrid = deal.isPricingGrid
          ? []
          : dealStepper
            ? [2, 8]
            : [5]
        setDisabledSteps(newDisabledSteps.concat(disableAmortization, disablePik, disablePricingGrid))
        SuccessNotification({
          title: 'Successful Facility Upsert',
          message:
            'You can make changes to Lender Allocations, Interest Rate Options, Fees and Amortization Schedules',
        })
      } catch {
        ErrorNotification({
          title: 'Error',
          message: 'Please Check Inputs',
        })
      }
    }

    const adminAgents: SelectItem[] = entities
      ?.filter(entity =>
        entity.entityProfile.some(profile => entityProfiles.find(option => option.id === profile.id)?.roles.includes(EntityRole.AdminAgentRole)))
      .map((entity: EntityParams) => {
        return { label: entity.entityName + ' - ' + entity.mei, value: entity.id ?? '' }
      })

    return (
      <FormWrapper title={`${facility ? 'Edit' : 'Create'} Facility`}>
        <Stack>
          {amendment && (
            <Group noWrap w="100%">
              <CustomDatePicker
                label={'Amendment Date'}
                date={form.values.amendmentDate ?? businessDate}
                setDate={(value: any) => {
                  form.setFieldValue('amendmentDate', value)
                }}
                holidayCalendars={
                  deal?.holidayCalendar ?? form.values.holidayCalendar ?? []
                }
              />
            </Group>
          )}
          <Group noWrap w="100%">
            <TextInput
              withAsterisk
              w="100%"
              name="name"
              id="name"
              label="Facility Name"
              placeholder="Facility Name is Auto-Generated"
              {...form.getInputProps('name')}
              disabled
            />
            <Select
              w="100%"
              searchable
              clearable
              label="Base Currency"
              placeholder="Select a Base Currency"
              data={currencyOptions ?? []}
              onChange={(e: string) => {
                handleCurrencyChange(e)
              }}
              value={form.values.baseCurrency}
            />
          </Group>
          <Group noWrap w="100%">
            <Select
              w="100%"
              clearable
              searchable
              withAsterisk
              label="Facility Type"
              placeholder="Select a Facility Type"
              data={staticFacilityTypes ?? []}
              onChange={(e: string) => handleFacilityTypeChange(e)}
              value={form.values.facilityType}
            />

            <Select
              hidden={deal != undefined}
              w="100%"
              clearable
              searchable
              withAsterisk
              label="Deal"
              placeholder="Select a Deal"
              disabled={deal ? true : false}
              data={dealsOptions ?? []}
              onChange={(e: string) => handleDealChange(e)}
              value={form.values.dealId}
            />
          </Group>
          <Group noWrap w="100%">
            <TextInput
              w="100%"
              label="Facility CUSIP"
              name="cusip"
              id="cusip"
              placeholder="Enter Facility CUSIP"
              minLength={9}
              maxLength={9}
              disabled={amendment != null ? true : false}
              {...form.getInputProps('cusip')}
              onChange={(e) => form.setFieldValue('cusip', e.target.value.toUpperCase())}
              error={
                !form.values.cusip || form.values.cusip.length == 9
                  ? null
                  : 'Cusip Should Be 9 Characters'
              }
            />
            <TextInput
              w="100%"
              label="Facility ISIN"
              name="isin"
              id="isin"
              placeholder="Enter Facility ISIN"
              minLength={12}
              maxLength={12}
              disabled={amendment != null ? true : false}
              {...form.getInputProps('isin')}
              onChange={(e) => form.setFieldValue('isin', e.target.value.toUpperCase())}
              error={
                !form.values.isin || form.values.isin.length == 12
                  ? null
                  : 'ISIN Should Be 12 Characters'
              }
            />

            <TextInput
              w="100%"
              label="Facility LXID"
              name="lxId"
              id="lxId"
              placeholder="Enter Facility LXID"
              minLength={8}
              maxLength={8}
              disabled={amendment != null ? true : false}
              {...form.getInputProps('lxId')}
              onChange={(e) => form.setFieldValue('lxId', e.target.value.toUpperCase())}
              error={
                !form.values.lxId || form.values.lxId.length == 8
                  ? null
                  : 'LXID Should Be 8 Characters'
              }
            />
            <TextInput
              w="100%"
              label="Facility BBG ID"
              name="bbgId"
              id="bbgId"
              placeholder="Enter Facility Bloomberg Id"
              minLength={9}
              maxLength={9}
              disabled={amendment != null ? true : false}
              {...form.getInputProps('bbgId')}
              onChange={(e) => form.setFieldValue('bbgId', e.target.value.toUpperCase())}
              error={
                form.values.bbgId && form.values.bbgId.length > 0 && form.values.bbgId.length !== 9
                  ? 'BBG ID Should Be 9 Characters'
                  : null
              }
            />
          </Group>
          <Group noWrap w="100%">
            <Select
              clearable
              searchable
              w="100%"
              placeholder="Select Administrative Agent"
              label="Administrative Agent"
              data={adminAgents ?? []}
              {...form.getInputProps('adminEntity')}
              onChange={e => handleAdminChange(e)}
              value={form.values.adminEntity}
            />
            <MultiSelect
              searchable
              w="100%"
              placeholder="Select Wire Instructions"
              label="Admin Agent Instructions"
              name="adminAgentPaymentInstructions"
              data={adminWireInstructionsArray ?? []}
              {...form.getInputProps('adminAgentPaymentInstructions')}
            />
          </Group>
          <Group noWrap w="100%">
            <MultiSelect
              w="100%"
              searchable
              label="Available Currencies"
              placeholder="Select a Currencies"
              data={currencyOptions ?? []}
              {...form.getInputProps('currencies')}
            />
            <NumberInput
              w="100%"
              withAsterisk
              pb={5}
              label="Amount"
              id="amount"
              placeholder="Enter Amount"
              parser={value => (value ? value.replace(/\$\s?|(,*)/g, '') : '')}
              formatter={value =>
                !Number.isNaN(parseFloat(value ?? ''))
                  ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  : ''
              }
              {...form.getInputProps('amount')}
              onChange={(e: number) => {
                handleAmountChange(e)
              }}
            />
          </Group>
          <Group noWrap w="100%">
            <Select
              clearable
              searchable
              w="100%"
              placeholder="Select PIK"
              label="PIK Options"
              data={pikOptions ?? []}
              {...form.getInputProps('pikOption')}
            />

            <Select
              clearable
              searchable
              w="100%"
              disabled={
                form.getInputProps('facilityType').value === 'TL' ||
                form.getInputProps('facilityType').value === 'TLA' ||
                form.getInputProps('facilityType').value === 'TLB' ||
                form.getInputProps('facilityType').value === 'TLC'
              }
              placeholder="Is it Sublimits"
              label="Sublimits"
              data={strBooleanOptions}
              {...form.getInputProps('isSublimit')}
            />
          </Group>
          <Group noWrap w="100%">
            <CustomDatePicker
              label={'Start Date'}
              date={form.values.startDate}
              setDate={(value: any) => {
                form.setFieldValue('startDate', value)
                updateDynamicName('date', value)
              }}
              holidayCalendars={
                deal?.holidayCalendar ?? form.values.holidayCalendar ?? []
              }
            />

            <CustomDatePicker
              label={'Maturity Date'}
              date={form.values.maturity}
              setDate={(value: any) => {
                form.setFieldValue('maturity', value)
              }}
              holidayCalendars={
                deal?.holidayCalendar ?? form.values.holidayCalendar ?? []
              }
            />
            {selectedDeal &&
              form.values.maturity > stringToDate(selectedDeal.maturity) ? (
              <span style={{ color: '#dc4d01' }}>
                Maturity date should not be after Deal maturity.
              </span>
            ) : null}
          </Group>
          <Group noWrap w="100%">
            <MultiSelect
              searchable
              w="100%"
              placeholder="Borrowers"
              label="Borrowers"
              name="borrowers"
              data={borrowerDropdown ?? []}
              onChange={e => handleBorrowersChange(e)}
              value={form.values.borrowers}
            />

            <MultiSelect
              searchable
              w="100%"
              placeholder="Select Wire Instructions"
              label="Borrower Wire Instructions"
              name="borrowerPaymentInstructions"
              data={borrowerWireInstructionsArray ?? []}
              {...form.getInputProps('borrowerPaymentInstructions')}
            />
          </Group>
          <Group noWrap w="100%">
            <MultiSelect
              searchable
              w="100%"
              placeholder="Select Lender Eligibility Criteria"
              label="Lender Eligibility Criteria"
              name="lenderEligibilityCriteria"
              data={lenderEligibilityCriteriaOptions ?? []}
              {...form.getInputProps('lenderEligibilityCriteria')}
            />

            <Select
              clearable
              searchable
              w="100%"
              placeholder="Select Ops Admin"
              label="Ops Admin"
              data={
                contacts && contacts.length > 0
                  ? contacts
                    .filter(
                      contact =>
                        contact.entity &&
                        contact.entity.id.replace(/['"]/g, '') ===
                        form.values.adminEntity
                    )
                    .map(contact => ({
                      value: contact.id ?? '',
                      label: contact.name,
                    }))
                  : []
              }
              {...form.getInputProps('opsAdmin')}
            />
          </Group>
          <Group noWrap w="100%">
            <MultiSelect
              w="100%"
              searchable
              label="Holiday Calendar"
              placeholder="Select a Holiday Calendar"
              data={holidayCalendarsOptions ?? []}
              {...form.getInputProps('holidayCalendar')}
            />
          </Group>
        </Stack>
      </FormWrapper>
    )
  }

export default forwardRef(UpdatedFacilitiesModal)
