import CreatePaymentButton from '../payments/create-payment-btn'
import CreateNoticeButton from '../notices/create-notice-btn'
import { Group, Stack } from '@mantine/core'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import HistoryToggleButton from 'app/views/components/buttons/HistoryToggleButton'
import { PaymentsPage } from '../payments/payments-page'
import { NoticesPage } from '../notices/notices-page'

interface props {
  tab: string
}
export function ReleaseQueueDrillPage({ tab }: props) {

  const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; pageRender: JSX.Element; createButton: JSX.Element; historyLink: string; } } = {
    releaseQueuePayments: { route: `/releasequeue`, label: 'PAYMENTS', active: tab === 'releaseQueuePayments' ? true : false, pageRender: <PaymentsPage />, createButton: <CreatePaymentButton />, historyLink: '/releasequeue/archived' },
    releaseQueueNotices: { route: `/releasequeue/notices`, label: 'NOTICES', active: tab === 'releaseQueueNotices' ? true : false, pageRender: <NoticesPage />, createButton: <CreateNoticeButton />, historyLink: '/releasequeue/notices/archived' },
  }

  return (
    <Stack w="100%">
      <Group w="100%" position="apart" className='tabsGapsActivity'>
         <Group position="right">
             <SegmentControlUpdated tabs={tabs} />
         </Group>
         <Group noWrap>
          <HistoryToggleButton navLink={tabs[tab].historyLink} />
          {tabs[tab].createButton}
        </Group>
     </Group>
      {tabs[tab].pageRender}
    </Stack>

  )
}
