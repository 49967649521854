import { EntityProfileParams } from 'app/models/entity-profile-params'

export const getEntityProfiles = (state: {
  entityProfiles: { entityProfiles: EntityProfileParams[] }
}) => {
  return state.entityProfiles.entityProfiles
}

export const getEntityProfile = (state: {
  entityProfiles: { entityProfile: EntityProfileParams }
}) => {
  return state.entityProfiles.entityProfile
}

export const getEntityProfileLoader = (state: {
  entityProfiles: { isLoading: boolean }
}) => {
  return state.entityProfiles.isLoading
}
