import React from 'react';
import { Pagination } from '@mantine/core';

interface PaginationControlsProps {
    activePage: number;
    totalItems: number;
    itemsPerPage: number;
    onPageChange: (page: number) => void;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({ activePage, totalItems, itemsPerPage, onPageChange }) => {
    return (
        <Pagination
            value={activePage}
            onChange={onPageChange}
            total={Math.ceil(totalItems / itemsPerPage)}
            className="pagination-controls"
        />
    );
};

export default PaginationControls;