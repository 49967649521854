import * as types from './types'
import { EntityTypeParams } from 'app/models/entity-type-params'
import { createReducer } from 'app/state/utils'

export interface EntityTypeState {
  entityTypes: EntityTypeParams[]
  entityType?: EntityTypeParams
  isLoading: boolean
}

const initialState: EntityTypeState = {
  entityTypes: [],
  entityType: undefined,
  isLoading: false,
}

const entityTypesReducer = createReducer(initialState)({
  [types.GET_ENTITY_TYPES]: (state: any, payload: any) => {
    return { ...state, entityTypes: payload.payload }
  },
  [types.GET_ENTITY_TYPE]: (state: any, payload: any) => {
    return { ...state, entityType: payload.payload }
  },
  [types.ADD_ENTITY_TYPE]: (state: any, payload: any) => {
    return {
      ...state,
      entityTypes: [payload.payload.data, ...state.entityTypes],
    }
  },
  [types.EDIT_ENTITY_TYPE]: (state: any, payload: any) => {
    const index = state.entityTypes.findIndex(
      (entityType: { id: any }) => entityType.id === payload.payload.data.id,
    )
    const newArray = [...state.entityTypes]
    newArray[index] = { ...payload.payload.data }
    return {
      ...state,
      entityTypes: newArray,
      entityType: { ...payload.payload.data },
    }
  },
  [types.DELETE_ENTITY_TYPES]: (state: any, payload: any) => {
    const filteredEntityTypes = state.entityTypes.filter((entityType: any) => {
      return entityType.id !== payload.payload[0].id
    })

    return {
      ...state,
      entityTypes: filteredEntityTypes,
    }
  },
  [types.START_LOADING_ENTITY_TYPES]: (state: EntityTypeState) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_ENTITY_TYPES]: (state: EntityTypeState) => {
    return { ...state, isLoading: false }
  },
})

export default entityTypesReducer
