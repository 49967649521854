import {
    Group,
    Stack,
} from '@mantine/core'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getEntity } from 'app/state/ducks/entities/selectors'
import { loadOneEntity } from 'app/state/ducks/entities/thunks'
import { ContractType } from 'app/models/common-types'
import { loadNoteByContract } from 'app/state/ducks/notes/thunks'
import ManageEntityDeals from './manage-entity-deals'
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle'
import EntityOverviewSection from '../components/entity-overview-section'
import NotesAndAttachment from 'app/views/components/notes-and-attachemnt/notes-and-attachment-full'

export default function PositionsPage() {
    const { entityId, status } = useParams()
    const entity = useSelector(getEntity)

    const dispatch = useDispatch()

    useEffect(() => {
        if (entityId) {
            dispatch(loadOneEntity(entityId, status ?? 'Inactive'))
            dispatch(loadNoteByContract(entityId, ContractType.Entities))
        }
    }, [entityId])

    return (
        <Stack w='100%' h='130vh'>
            <SectionTitle>Positions List</SectionTitle>
            <Stack className='sectionDetailBody tablePadding'>
                <ManageEntityDeals entity={entity} />
            </Stack>
            <EntityOverviewSection entity={entity} />
            <Group w="100%" noWrap mt='30px'>
                <NotesAndAttachment
                    itemId={entityId ?? ''}
                    type={ContractType.Entities}
                    itemAdmin={entity?.maker ?? ''}
                />
            </Group>
        </Stack>
    )
}