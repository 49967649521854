import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { TradeEntryDrillPage } from './trade-entry-drill-page'


export function TradeEntryLandingPage(tab: { tab: string }) {
  return (
    <PageLayout>
      <PageTitle pageTitle='Trade Entry' />
      <TradeEntryDrillPage tab={tab.tab} />
    </PageLayout>
  )
}
