import { EntityTypeParams } from 'app/models/entity-type-params'

export const getEntityTypes = (state: {
  entityTypes: { entityTypes: EntityTypeParams[] }
}) => {
  return state.entityTypes.entityTypes
}

export const getEntityType = (state: {
  entityTypes: { entityType: EntityTypeParams }
}) => {
  return state.entityTypes.entityType
}

export const getEntityTypeLoader = (state: {
  entityTypes: { isLoading: boolean }
}) => {
  return state.entityTypes.isLoading
}
