import React from 'react';
import { Group } from '@mantine/core';
import ExportButton from './export-button';
import RowsPerPageSelect from './rows-per-page-select';
import PaginationControls from './pagination-controls';

interface ControlSectionProps {
    itemsPerPage: number;
    activePage: number;
    totalItems: number;
    onItemsPerPageChange: (value: string) => void;
    onPageChange: (page: number) => void;
    onExport: () => void;
}

const ControlSection: React.FC<ControlSectionProps> = ({
    itemsPerPage,
    activePage,
    totalItems,
    onItemsPerPageChange,
    onPageChange,
    onExport,
}) => {
    return (
        <Group position="apart" mt="lg" style={{ marginTop: '20px' }}>
            <ExportButton onClick={onExport} />
            <Group align="center" spacing="xs">
                <RowsPerPageSelect itemsPerPage={itemsPerPage} onItemsPerPageChange={onItemsPerPageChange} />
                <PaginationControls activePage={activePage} totalItems={totalItems} itemsPerPage={itemsPerPage} onPageChange={onPageChange} />
            </Group>
        </Group>
    );
};

export default ControlSection;
