import IonIcon from '@reacticons/ionicons'
import { ReactNode } from 'react';

export interface Route {
  link: string
  label: string
  icon?: ReactNode | (() => ReactNode);
  alt?: string
  isExtended?: boolean
  isExtendedMain?: boolean
  key?: string;
}


export const Data: Route[] = [
  {
    link: '/mywork',
    label: 'My Work',
    icon: () => <IonIcon name="tv" size="large" className="ionicon" />,
    alt: 'Account Management Icons',
    key: 'myWork',
  },
  {
    link: '/entitymanagement',
    label: 'Entities',
    icon: () => <IonIcon name="folder-open" size="large" className="ionicon"
    />,
    alt: 'Account Management Icons',
    key: 'entityManagement',
  },
  {
    link: '/dealmanagement',
    label: 'Deals',
    icon: () => <IonIcon name="briefcase" size="large" className="ionicon" />,
    alt: 'deal-management-icon',
    key: 'dealManagement',
  },
  {
    link: '/positions',
    label: 'Positions',
    icon: () => <IonIcon name="cellular" size="large" className="ionicon" />,
    alt: 'positions-icon',
    key: 'positions',
  },
  {
    link: '/assetservicing',
    label: 'Servicing',
    icon: () => <IonIcon name="card" size="large" className="ionicon" />,
    alt: 'asset-servicing-icon',
    key: 'servicing',
  },
  {
    link: '/releasequeue',
    label: 'Release Queue',
    icon: () => <IonIcon name="file-tray-full" size="large" className="ionicon" />,
    alt: 'release-queue-icon',
    key: 'releaseQueue',
  },
  {
    link: '/tradeclosing',
    label: 'Trades',
    icon: () => <IonIcon name="repeat" size="large" className="ionicon" />,
    alt: 'trade-closing-icon',
    key: 'tradeClosing',
  },
  // {
  //   link: '/reports',
  //   label: 'Reports',
  //   icon: () => <IonIcon name="newspaper" size="large" />,
  //   alt: 'reports-icon',
  //   key: 'reports',
  // },
  {
    link: 'divider',
    label: '',
    key: 'divider',
  },
  {
    link: '/indexrateoptions',
    label: 'Maintenance',
    icon: () => <IonIcon name="apps" size="large" className="ionicon" />,
    alt: 'table-maintenance-icon',
    isExtendedMain: false,
    key: 'tableMaintenance',
  },
  {
    link: '/indexrateoptions',
    label: 'Index Rate Options',
    icon: () => <IonIcon name="bar-chart" size="large" className="ionicon" />,
    alt: 'interest-rate-options-icon',
    isExtended: true,
    key: 'indexRateOptions',
  },
  {
    link: '/usermanagement',
    label: 'Users',
    icon: () => <IonIcon name="people" size="large" className="ionicon" />,
    alt: 'user-management-icon',
    isExtended: true,
    key: 'userManagement',
  },
  {
    link: '/entityprofile',
    label: 'Entity Profile',
    icon: () => <IonIcon name="folder-open" size="large" className="ionicon"
    />,
    isExtended: true,
    alt: 'Account Management Icons',
    key: 'entityProfile',
  },
  {
    link: '/entitytype',
    label: 'Entity Type',
    icon: () => <IonIcon name="folder-open" size="large" className="ionicon"
    />,
    isExtended: true,
    alt: 'Account Management Icons',
    key: 'entityType',
  },
  {
    link: '/jobmanagement',
    label: 'Jobs',
    icon: () => <IonIcon name="receipt" size="large" className="ionicon" />,
    alt: 'job-management-icon',
    key: 'jobManagement',
  },
  {
    link: '/holidaycalendars',
    label: 'Calendars',
    icon: () => <IonIcon name="calendar" size="large" className="ionicon" />,
    alt: 'holiday-calendars-icon',
    key: 'holidayCalendars',
  },
  // {
  //   link: '/simulations',
  //   label: 'Simulate',
  //   icon: <IonIcon name="play-circle" size="large" />,
  //   alt: 'simulation-icon',
  //   key: 'simulations',
  // },
]

export const nestedKeys = ['userManagement', 'indexRateOptions', 'entityProfile', 'entityType',];