import { MRT_ColumnDef } from 'mantine-react-table'
import { Text } from '@mantine/core'
import { ReactNode } from 'react'



function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export const colsAmoritzationSchedule: MRT_ColumnDef<{ balance?: string, facilityAmount?: string, paymentAmount?: string, paymentDueDate?: string, status?: string, actions: ReactNode }>[] = [
  {
    accessorKey: 'paymentDueDate',
    header: 'Payment Due Date',
    Header: <TableHead header={'Payment Due Date'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'facilityAmount',
    header: 'Facility Amount',
    Header: <TableHead header={'Facility Amount'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'paymentAmount',
    header: 'Payment Amount',
    Header: <TableHead header={'Payment Amount'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'balance',
    header: 'Balance',
    Header: <TableHead header={'Balance'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    Header: <TableHead header={'Status'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'actions',
    header: 'Actions',
    Header: <TableHead header={'Actions'} />,
    enableColumnActions: false,
    enableColumnFilter: false,
    Cell: ({ cell }) => cell.getContext().row.original.actions,
  },
]