import React from 'react';
import { Switch, Text, Group } from '@mantine/core';

interface StatusSwitchProps {
    isActive: boolean;
    onToggle: (isActive: boolean) => void;
}

const StatusSwitch: React.FC<StatusSwitchProps> = ({ isActive, onToggle }) => (
    <Group align="center">
        <Switch
            label={
                <Text className={`status-switch-label ${isActive ? 'active' : ''}`}>
                    {isActive ? 'ACTIVE' : 'INACTIVE'}
                </Text>
            }
            checked={isActive}
            onChange={(e) => onToggle(e.currentTarget.checked)}
            size="md"
            classNames={{
                track: `status-switch-track ${isActive ? 'active' : ''}`,
                thumb: `status-switch-thumb ${isActive ? 'active' : 'inactive'}`,
            }}
        />
    </Group>
);

export default StatusSwitch;
