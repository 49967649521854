import { HoverCard, Group, Text } from '@mantine/core'
import { WireInstructionParams } from 'app/models/wire-instruction-params'

interface Props {
  wireInstruction: WireInstructionParams
}


export default function WireInstructionHoverCard({ wireInstruction }: Props) {
  return (
    <HoverCard width={580} shadow="md" arrowPosition='side' position='right' arrowOffset={10} arrowSize={20} withinPortal withArrow>
      <HoverCard.Target>
        <Text className='sectionItemTitleClickable' style={{ fontSize: '15px' }}>{wireInstruction.currency}</Text>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <div>
          <div
            style={{
              backgroundColor: '#95A4FD',
              padding: '10px',
              width: '100%',
              color: 'white',
              fontSize: '14px',
              fontWeight: 'bold',
              borderRadius: '8px 8px 0px 0px',
            }}
          >
            Wire Instruction
          </div>
          <div style={{ padding: '15px', flexDirection: 'column' }}>
            <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
              <Text
                fz="14px"
                fw={800}
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                  color: '#32439A',

                }}
              >
                Currency
              </Text>
              <Text
                fz="14px"
                fw={400}
                sx={{
                  fontFamily: 'Plus Jakarta Sans',

                }}
              >
                {wireInstruction.currency}
              </Text>
            </Group>
            <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
              <Text
                fz="14px"
                fw={800}
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                  color: '#32439A',

                }}
              >
                Bank ABA #
              </Text>
              <Text
                fz="14px"
                fw={400}
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                }}

              >
                {wireInstruction.accountWithInstitutionBankAba}
              </Text>
            </Group>
            <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
              <Text
                fz="14px"
                fw={800}
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                  color: '#32439A',
                }}
              >
                Account with Institution Name
              </Text>
              <Text
                fz="14px"
                fw={400}
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                }}
              >
                {wireInstruction.accountWithInstitutionName}
              </Text>
            </Group>
            <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
              <Text
                fz="14px"
                fw={800}
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                  color: '#32439A',

                }}
              >
                Beneficiary Customer Name
              </Text>
              <Text
                fz="14px"
                fw={400}
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                }}
              >
                {wireInstruction.beneficiaryCustomerName}
              </Text>
            </Group>
            <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
              <Text
                fz="14px"
                fw={800}
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                  color: '#32439A',

                }}
              >
                Beneficiary Account #
              </Text>
              <Text
                fz="14px"
                fw={400}
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                }}
              >
                {wireInstruction.beneficiaryAccountNumber}

              </Text>
            </Group>
            <Group position="apart" style={{ paddingTop: 5, paddingBottom: 5 }}>
              <Text
                fz="14px"
                fw={800}
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                  color: '#32439A',
                }}
              >
                Payment Reference
              </Text>
              <Text
                fz="14px"
                fw={400}
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                }}
              >
                {wireInstruction.paymentReference}
              </Text>
            </Group>
          </div>
        </div>
      </HoverCard.Dropdown>
    </HoverCard>
  )
}