import React from 'react';
import { Box, Group, Text, Switch } from '@mantine/core';
import { EntityTypes } from 'app/models/entity-type-params';

interface RolesSectionProps {
    roles: EntityTypes[];
    onToggleRole: (role: EntityTypes, checked: boolean) => void;
}

const RolesSection: React.FC<RolesSectionProps> = ({ roles, onToggleRole }) => {
    const allRoles: EntityTypes[] = [
        EntityTypes.EntityName,
        EntityTypes.MEI,
        EntityTypes.InstitutionType,
        EntityTypes.KycType,
        EntityTypes.Address,
        EntityTypes.AddressLine2,
        EntityTypes.City,
        EntityTypes.State,
        EntityTypes.PostalCode,
        EntityTypes.Parent,
        EntityTypes.ParentMEI,
        EntityTypes.FundManagerName,
        EntityTypes.FundManagerMEI,
        EntityTypes.LEI,
        EntityTypes.CEI,
        EntityTypes.NAICCode,
        EntityTypes.CRN,
        EntityTypes.EIN,
        EntityTypes.GIIN,
        EntityTypes.EntityReference,
        EntityTypes.UsTaxFormType,
        EntityTypes.TaxFormStatus,
        EntityTypes.KycStatus,
        EntityTypes.CountryOfIncorporation,
        EntityTypes.CountryOfTaxResidence,
    ];

    const roleLabels: { [key in EntityTypes]: string } = {
        [EntityTypes.EntityName]: 'Entity Name',
        [EntityTypes.MEI]: 'MEI (Market Entity Identifier)',
        [EntityTypes.LEI]: 'LEI',
        [EntityTypes.Address]: 'Address',
        [EntityTypes.UsTaxFormType]: 'US Tax Form Type',
        [EntityTypes.InstitutionType]: 'Institution Type',
        [EntityTypes.CEI]: 'CEI',
        [EntityTypes.TaxFormStatus]: 'Tax Form Status',
        [EntityTypes.AddressLine2]: 'Address Line 2',
        [EntityTypes.KycType]: 'KYC Type',
        [EntityTypes.NAICCode]: 'NAIC Code',
        [EntityTypes.KycStatus]: 'KYC Status',
        [EntityTypes.CountryRegion]: 'Country / Region',
        [EntityTypes.Parent]: 'Parent',
        [EntityTypes.CRN]: 'CRN',
        [EntityTypes.CountryOfIncorporation]: 'Country Of Incorporation',
        [EntityTypes.City]: 'City',
        [EntityTypes.ParentMEI]: 'Parent MEI',
        [EntityTypes.EIN]: 'EIN',
        [EntityTypes.CountryOfTaxResidence]: 'Country Of Tax Residence',
        [EntityTypes.State]: 'State',
        [EntityTypes.FundManagerName]: 'Fund Manager',
        [EntityTypes.GIIN]: 'GIIN',
        [EntityTypes.PostalCode]: 'ZIP / Postal Code',
        [EntityTypes.FundManagerMEI]: 'Fund Manager MEI',
        [EntityTypes.EntityReference]: 'GIIN',
    };

    return (
        <Box className="roles-container" >
            <Text className="roles-title">
                Mandatory Fields:
            </Text>
            <Group
                spacing="md"
                mb="md"
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                }}
            >
                {allRoles.map((role) => (
                    <Box key={role} className="role-item">
                        <Text className="role-text">
                            {roleLabels[role]}
                        </Text>
                        <Switch
                            checked={roles.includes(role ?? '')}
                            onChange={(e) => onToggleRole(role, e.currentTarget.checked)}
                            size="md"
                            classNames={{
                                track: `custom-switch-track ${roles.includes(role) ? 'active' : ''}`,
                                thumb: `custom-switch-thumb ${roles.includes(role) ? 'active' : 'inactive'}`,
                            }}
                        />
                    </Box>
                ))}
            </Group >
        </Box >
    );
};

export default RolesSection;
