import React from 'react';
import { Select, Box } from '@mantine/core';

interface KycTypeSelectProps {
    value: string;
    onChange: (value: string) => void;
}

const KycTypeSelect: React.FC<KycTypeSelectProps> = ({ value, onChange }) => (
    <Select
        placeholder="KYC Type"
        value={value}
        onChange={(selected) => onChange(selected || '')}
        data={['Lite', 'Full']}
        className="kyc-type-select"
        rightSection={
            <Box className="kyc-type-select-right-section">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                    <path d="M6 9l6 6 6-6" />
                </svg>
            </Box>
        }
    />
);

export default KycTypeSelect;
