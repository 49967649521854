import React from 'react';

export function TradeEntryTable() {
  return (
    <table className="tradeEntryTable">
      <thead>
        <tr>
          <th>Facility Name</th>
          <th>Traded Amount</th>
          <th>Price</th>
          <th>CUSIP</th>
          <th>LX ID</th>
          <th>Facility ID</th>
          <th>Client Facility ID</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='tradeEntryTdBold'>Facility Name One</td>
          <td className='tradeEntryTdInput'>$100,000.00</td>
          <td className='tradeEntryTdInput'>100.00%</td>
          <td>US5738493</td>
          <td>LX388478</td>
          <td>Facility ID</td>
          <td>Client Facility ID</td>
        </tr>
        <tr>
          <td className='tradeEntryTdBold'>Facility Name Two</td>
          <td className='tradeEntryTdInput'>$100,000.00</td>
          <td className='tradeEntryTdInput'>98.00%</td>
          <td>US5738493</td>
          <td>LX388478</td>
          <td>Facility ID</td>
          <td>Client Facility ID</td>
        </tr>
        <tr>
          <td className='tradeEntryTdBold'>Facility Name Three</td>
          <td className='tradeEntryTdInput'>$100,000.00</td>
          <td className='tradeEntryTdInput'>75.00%</td>
          <td>US5738493</td>
          <td>LX388478</td>
          <td>Facility ID</td>
          <td>Client Facility ID</td>
        </tr>
        <tr>
          <td className='tradeEntryTdBold'>Facility Name Four</td>
          <td className='tradeEntryTdInput'>$100,000.00</td>
          <td className='tradeEntryTdInput'>82.00%</td>
          <td>US5738493</td>
          <td>LX388478</td>
          <td>Facility ID</td>
          <td>Client Facility ID</td>
        </tr>
      </tbody>
    </table>
  );
}