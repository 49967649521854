import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocation } from 'react-router-dom'
import { Navbar, Divider } from '@mantine/core'
import config from 'app/config/config'
import ChevronIcon from './ChevronIcon';
import NavigationItem from './NavigationItem';
import { Data, nestedKeys } from './types';
import AppVersionInfo from './AppVersionInfo';
import IonIcon from '@reacticons/ionicons'

type LayoutNavBarProps = {
  open: boolean
  setOpen: (open: boolean) => void
}

export const LayoutNavBar: React.FC<LayoutNavBarProps> = ({ open, setOpen }) => {
  const { isAuthenticated } = useAuth0()
  const location = useLocation()
  const [data, setData] = useState(Data);

  if (!isAuthenticated) return null;

  const changeTab = (key: string) => {
    const newData = data
    if (key === 'tableMaintenance') {
      const index = newData.findIndex(item => item.key === key)
      newData[index].isExtendedMain = !newData[index].isExtendedMain;
      nestedKeys.forEach(nestedKey => {
        const nestedIndex = newData.findIndex(item => item.key === nestedKey)
        if (nestedIndex !== -1) {
          newData[nestedIndex].isExtended = !newData[nestedIndex].isExtended;
        }
      });
    }
    setData(newData);
  }

  const isTableMaintenanceOpen = data.find(item => item.key === 'tableMaintenance')?.isExtendedMain;


  return (
    <Navbar width={{ base: open ? '20em' : '6.25em' }} px="10px" pt="10px" pb="10px" sx={{
      justifyContent: 'space-between',
      backgroundColor: '#F1ECEC',
    }}>
      {data.map((item) => (
        item.key === 'divider' ? (
          <div key={item.key}>
            <Divider size="sm" style={{ flex: 1 }} />
            <ChevronIcon open={open} onClick={() => setOpen(!open)} />
          </div>
        ) : item.key === 'tableMaintenance' ? (
          <div key={item.key} style={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}>
            <NavigationItem
              key={item.key}
              item={item}
              open={open}
              activePath={location.pathname}
              isExtendedMain={isTableMaintenanceOpen && ['indexRateOptions', 'userManagement', 'tableMaintenance', 'entityProfile', 'entityType'].includes(item.key)}
              onClick={() => changeTab(item.key ?? '')}
            />
            {open && (item.isExtendedMain ? (
              <IonIcon name='chevron-forward-outline' style={{ marginLeft: '-25px', pointerEvents: 'none', fontSize: '12px' }} />
            ) : (
              <IonIcon name='chevron-down-outline' style={{ marginLeft: '-25px', pointerEvents: 'none', fontSize: '12px' }} />
            ))}
          </div>
        ) : (
          <div key={item.key}>
            <NavigationItem
              key={item.key}
              item={item}
              open={open}
              activePath={location.pathname}
              isExtendedMain={isTableMaintenanceOpen && ['indexRateOptions', 'userManagement', 'tableMaintenance', 'entityProfile', 'entityType'].includes(item.key ?? '')}
              onClick={() => changeTab(item.key ?? '')}
            />
          </div>
        )
      ))
      }
      <AppVersionInfo version={config.appVersion} releaseDate={config.releaseDate} open={open} />
    </Navbar >
  )
}