import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getEntityProfiles,
  getEntityProfile,
  addEntityProfile,
  editEntityProfile,
  deleteEntityProfiles,
  startLoadingEntityProfiles,
  stopLoadingEntityProfiles,
} from 'app/state/ducks/entity-profile/actions'
import { EntityProfileParams } from 'app/models/entity-profile-params'

export const saveEntityProfile =
  (savedEntityProfile: EntityProfileParams, entityProfile?: string) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    if (entityProfile) {
      savedEntityProfile.id = entityProfile
      return client
        .put(`${apiServerUrl}/api/entity-profiles`, savedEntityProfile)
        .then(entityProfile => {
          dispatch(editEntityProfile(entityProfile))
          return { success: true, payload: entityProfile }
        })
        .catch(error => {
          return { success: false, payload: error.response.data.error }
        })
    } else {
      return client
        .post(`${apiServerUrl}/api/entity-profiles`, savedEntityProfile)
        .then(entityProfile => {
          dispatch(addEntityProfile(entityProfile))
          return { success: true, payload: entityProfile }
        })
        .catch(error => {
          return { success: false, payload: error.response.data.error }
        })
    }
  }

export const loadAllEntityProfiles = () => async (dispatch: Dispatch) => {
  dispatch(startLoadingEntityProfiles())
  const apiServerUrl = config.backendUrl
  try {
    const response = await client.get(`${apiServerUrl}/api/entity-profiles`)
    const entityProfiles = response.data
    dispatch(getEntityProfiles(entityProfiles))
  } catch (e) {
    console.log(e)
    dispatch(getEntityProfiles([]))
  } finally {
    dispatch(stopLoadingEntityProfiles())
  }
}

export const loadOneEntityProfile =
  (entityProfileId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(`${apiServerUrl}/api/entity-profiles/one?id=${entityProfileId}`)
      .then(entityProfile => {
        return dispatch(
          getEntityProfile({ ...entityProfile.data, status: status }),
        )
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const removeEntityProfiles =
  (entityProfile: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(`${apiServerUrl}/api/entity-profiles?ids[]=${entityProfile}`)
      .then(entityProfiles => {
        dispatch(deleteEntityProfiles(entityProfiles.data))
        return { success: true, payload: entityProfiles.data }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }
