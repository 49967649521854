import React from 'react';
import EntityItem from './entity-profile-item';
import EntityForm from './entity-profile-form';
import { Box } from '@mantine/core';
import { EntityProfileParams } from 'app/models/entity-profile-params';


interface EntityPairProps {
    entity1: EntityProfileParams;
    isExpanded1: boolean;
    onEdit: (a: EntityProfileParams) => void;
    setExpandedEntityId: React.Dispatch<React.SetStateAction<string | null>>;
    newEntity1: EntityProfileParams;
    entity2: EntityProfileParams | undefined;
    isExpanded2: boolean;
    newEntity2: EntityProfileParams;
}

const EntityPair: React.FC<EntityPairProps> = ({
    entity1,
    entity2,
    isExpanded1,
    isExpanded2,
    onEdit,
    setExpandedEntityId,
    newEntity1,
    newEntity2,
}) => {
    return (
        <>
            <EntityItem
                key={entity1.id}
                entity={entity1}
                isExpanded={isExpanded1}
                onEdit={() => onEdit(entity1)}
            />
            {entity2 && (
                <EntityItem
                    key={entity2.id}
                    entity={entity2}
                    isExpanded={isExpanded2}
                    onEdit={() => onEdit(entity2)}
                />
            )}
            {(isExpanded1 || isExpanded2) && (
                <Box mt="-10px" mb="-10px" sx={{ gridColumn: 'span 2' }}>
                    <EntityForm
                        newEntity={isExpanded1 ? newEntity1 : newEntity2}
                        setFormExpanded={() => setExpandedEntityId(null)}
                    />
                </Box>
            )}
        </>
    );
};

export default EntityPair;