import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { EntitiesTypeDrillPage } from './entities-type-drill-page'

export default function EntitiesTypePage(tab: { tab: string }) {
    return (
        <PageLayout>
            <PageTitle pageTitle='Entity Type' />
            <EntitiesTypeDrillPage tab={tab.tab} />
        </PageLayout>
    )
}
