import { SelectItem } from '@mantine/core'

export interface Option {
  [x: string]: any
  label: string
  value: string
}

export const contractPeriodsArray = [
  'Overnight',
  'One Week',
  'Two Week',
  'Three Week',
  'One Month',
  'Two Months',
  'Three Months',
  'Four Months',
  'Five Months',
  'Six Months',
  'Seven Months',
  'Eight Months',
  'Nine Months',
  'Ten Months',
  'Eleven Months',
  'One Year',
]

export interface boolOption {
  [x: string]: any
  label: string
  value: boolean
}

export const strBooleanOptions: Option[] = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]

export const booleanOptions: boolOption[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

export const isRiskFreeRateOptions: Option[] = [
  { label: 'Yes', value: 'True' },
  { label: 'No', value: 'False' },
]

export const paymentTypeOptions: Option[] = [
  { label: 'Full Payment', value: 'FullPaymentPrincipal' },
  { label: 'Prepayment', value: 'PrepaymentPrincipal' },
  { label: 'Mandatory', value: 'Mandatory' },
  { label: 'Interest Payment', value: 'InterestPayment' },
]

export const institutionFinancialTypeOptions: Option[] = [
  { label: 'Financial', value: 'FinancialInstitution' },
  { label: 'Non-Financial Institution', value: 'NonFinancialInstitution' },
]

export const preferredContactMethodOptions: Option[] = [
  { label: 'E-mail', value: 'email' },
  { label: 'Phone', value: 'phone' },
  { label: 'Fax', value: 'fax' },
]

export const kycTypeOptions: Option[] = [
  { label: 'Lite', value: 'Lite' },
  { label: 'Full', value: 'Full' },
  { label: 'N/A', value: '' },
]

export const contactTypeOptions: Option[] = [
  { label: 'Admin', value: 'Admin' },
  { label: 'Credit', value: 'Credit' },
]

export const contactTypeBorrowerOptions: Option[] = [
  { label: 'Signatory', value: 'Signatory' },
  { label: 'Preferred', value: 'Preferred' },
  { label: 'Alternative', value: 'Alternative' },
]

export const frequencyOptions: Option[] = [
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Quarterly', value: 'Quarterly' },
  { label: 'Bi-Annually', value: 'Semi' },
  { label: 'Annually', value: 'Annual' },
  { label: 'One-Time Payment', value: 'Bullet' },
]

export const feeTypeOptions: Option[] = [
  { label: 'Facility Fee', value: 'Facility' },
  { label: 'Commitment Fee', value: 'Commitment' },
  { label: 'Utilization Fee', value: 'Utilization' },
  { label: 'Letter of Credit Fee', value: 'LetterOfCredit' },
  { label: 'Arrangement Fee', value: 'Arrangement' },
  { label: 'Upfront Fee', value: 'Upfront' },
  { label: 'Amendment Fee', value: 'Amendment' },
  { label: 'Syndication Fee', value: 'Syndication' },
  { label: 'Other Ad Hoc Fee', value: 'AdHoc' },
]

export const dealFeeTypeOptions: Option[] = [
  { label: 'Arrangement Fee', value: 'ArrangementFee' },
  { label: 'Upfront Fee', value: 'UpfrontFee' },
  { label: 'Amendment Fee', value: 'AmendmentFee' },
  { label: 'Syndication Fee', value: 'SyndicationFee' },
  { label: 'Other Ad Hoc Fee', value: 'AdHocFee' },
  { label: 'Administrative Agency Fee', value: 'AdministrativeAgencyFee' },
]

export const statusOptions: Option[] = [
  { label: 'Pipeline', value: 'Pipeline' },
  { label: 'In Progress', value: 'In_Progress' },
  { label: 'Pending Approval', value: 'Pending_Approval' },
  { label: 'Active', value: 'Active' },
]

export const dayBasisOptions: Option[] = [
  { label: 'Actual/360', value: 'Actual_360' },
  { label: 'Actual/365', value: 'Actual_365' },
  { label: 'Actual/Actual', value: 'Actual_Actual' },
  { label: '30(Euro)/360', value: 'Euro30_360' },
  { label: '30(US)/360', value: 'US30_360' },
  { label: '30(Euro)/365', value: 'Euro30_365' },
  { label: '30(US)/365', value: 'US30_365' },
  { label: '30(Euro)/Actual', value: 'Euro30_Actual' },
  { label: '30(US)/Actual', value: 'US30_Actual' },
]

export const rateSettingInstructions: Option[] = [
  { label: 'Same Date', value: 'SameDate' },
  { label: '1 Day Prior', value: '1_Day_Prior' },
  { label: '2 Day Prior', value: '2_Day_Prior' },
  { label: '3 Day Prior', value: '3_Day_Prior' },
]

export const pikOptions: Option[] = [
  { label: 'PIK all', value: 'PIK_All' },
  { label: 'PIK optional', value: 'PIK_Optional' },
  { label: ' No PIK', value: 'No_PIK' },
  { label: 'PIK schedule', value: 'PIK_Scheduled' },
]

export const lenderEligibilityCriteriaOptions: Option[] = [
  { label: 'Existing Lender', value: 'Existing_Lender' },
  { label: 'Affiliate', value: 'Affiliate' },
  { label: 'Bank Entity', value: 'Bank_Entity' },
]

export const currencyOptions: Option[] = [
  { label: 'Argentine Peso-(ARS)', value: 'ARS' },
  { label: 'Australian Dollar-(AUD)', value: 'AUD' },
  { label: 'Brazilian Real-(BRL)', value: 'BRL' },
  { label: 'British Pound-(GBP)', value: 'GBP' },
  { label: 'Canadian Dollar-(CAD)', value: 'CAD' },
  { label: 'Chilean Peso-(CLP)', value: 'CLP' },
  { label: 'Colombian Peso-(COP)', value: 'COP' },
  { label: 'Czech Koruna-(CZK)', value: 'CZK' },
  { label: 'Egyptian Pound-(EGP)', value: 'EGP' },
  { label: 'Euro-(EUR)', value: 'EUR' },
  { label: 'Hong Kong Dollar-(HKD)', value: 'HKD' },
  { label: 'Hungarian Forint-(HUF)', value: 'HUF' },
  { label: 'Icelandic Krona-(ISK)', value: 'ISK' },
  { label: 'Indian Rupee-(INR)', value: 'INR' },
  { label: 'Indonesian Rupiah-(IDR)', value: 'IDR' },
  { label: 'Israeli New Shekel-(ILS)', value: 'ILS' },
  { label: 'Jamaican Dollar-(JMD)', value: 'JMD' },
  { label: 'Japanese Yen-(JPY)', value: 'JPY' },
  { label: 'Kuwaiti Dinar-(KWD)', value: 'KWD' },
  { label: 'Malaysian Ringgit-(MYR)', value: 'MYR' },
  { label: 'Mexican Peso-(MXN)', value: 'MXN' },
  { label: 'New Taiwan Dollar-(TWD)', value: 'TWD' },
  { label: 'New Zealand Dollar-(NZD)', value: 'NZD' },
  { label: 'Nigerian Naira-(NGN)', value: 'NGN' },
  { label: 'Norwegian Krone-(NOK)', value: 'NOK' },
  { label: 'Pakistani Rupee-(PKR)', value: 'PKR' },
  { label: 'Peruvian Sol-(PEN)', value: 'PEN' },
  { label: 'Philippine Peso-(PHP)', value: 'PHP' },
  { label: 'Polish Zloty-(PLN)', value: 'PLN' },
  { label: 'Qatari Rial-(QAR)', value: 'QAR' },
  { label: 'Renminbi-(CNY)', value: 'CNY' },
  { label: 'Romanian Leu-(RON)', value: 'RON' },
  { label: 'Russian Ruble-(RUB)', value: 'RUB' },
  { label: 'Saudi Riyal-(SAR)', value: 'SAR' },
  { label: 'Singapore Dollar-(SGD)', value: 'SGD' },
  { label: 'South African Rand-(ZAR)', value: 'ZAR' },
  { label: 'South Korean Won-(KRW)', value: 'KRW' },
  { label: 'Swedish Krona-(SEK)', value: 'SEK' },
  { label: 'Swiss Franc-(CHF)', value: 'CHF' },
  { label: 'Thai Baht-(THB)', value: 'THB' },
  { label: 'Turkish Lira-(TRY)', value: 'TRY' },
  { label: 'Ukrainian Hryvnia-(UAH)', value: 'UAH' },
  { label: 'UAE Dirham-(AED)', value: 'AED' },
  { label: 'US Dollar-(USD)', value: 'USD' },
  { label: 'Vietnamese Dong-(VND)', value: 'VND' },
]

export const taxFormOptions: Option[] = [
  { label: 'W-8BEN-E', value: 'W_8BEN_E' },
  { label: 'W-8ECI', value: 'W_8ECI' },
  { label: 'W-8EXP', value: 'W_8EXP' },
  { label: 'W-8IMY', value: 'W_8IMY' },
  { label: 'W-9', value: 'W_9' },
  { label: 'Other', value: 'Other_Tax_Type' },
]

export const taxFormStatusOptions: Option[] = [
  { label: 'Pending', value: 'Pending' },
  { label: 'Validated', value: 'Validated' },
  { label: 'In Review', value: 'In_Review' },
]

export const entityReferenceOptions: Option[] = [
  { label: 'Primary Entity', value: 'Primary' },
  { label: 'Disregarded Entity', value: 'Disregarded' },
]

export const countries: Option[] = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Åland Islands', value: 'AX' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cape Verde', value: 'CV' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, The Democratic Republic of the', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: "Cote D'Ivoire", value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
  { label: 'Holy See (Vatican City State)', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran, Islamic Republic Of', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: "Korea, Democratic People'S Republic of", value: 'KP' },
  { label: 'Korea, Republic of', value: 'KR' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: "Lao People'S Democratic Republic", value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libyan Arab Jamahiriya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia, Federated States of', value: 'FM' },
  { label: 'Moldova, Republic of', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Netherlands Antilles', value: 'AN' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestinian Territory, Occupied', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Reunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'RWANDA', value: 'RW' },
  { label: 'Saint Helena', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia and Montenegro', value: 'CS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Surilabel', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan, Province of China', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Viet Nam', value: 'VN' },
  { label: 'Virgin Islands, British', value: 'VG' },
  { label: 'Virgin Islands, U.S.', value: 'VI' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
]

export const states: Option[] = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
]

export const EntityTypeOptions: Option[] = [
  {
    label: 'Bank (FI/Broker-Dealer)',
    value: 'BankFIBrokerDealer',
  },
  {
    label: 'CLO',
    value: 'CLO',
  },
  {
    label: 'SPV',
    value: 'SPV',
  },
  {
    label: 'Regulated Fund: Mutual Fund',
    value: 'RegulatedFundMutualFund',
  },
  {
    label: 'Regulated Fund: ETF',
    value: 'RegulatedFundETF',
  },
  {
    label: 'Regulated Fund: BDC',
    value: 'RegulatedFundBDC',
  },
  {
    label: 'Regulated Fund: UCITS',
    value: 'RegulatedFundUCITS',
  },
  {
    label: 'Regulated Fund: SICAV',
    value: 'RegulatedFundSICAV',
  },
  {
    label: 'Regulated Fund: Unit Trust',
    value: 'RegulatedFundUnitTrust',
  },
  {
    label: 'Regulated Fund: Collective Investment Scheme',
    value: 'RegulatedFundCollectiveInvestmentScheme',
  },
  {
    label: 'Non Regulated Fund: Hedge Fund',
    value: 'NonRegulatedFundHedgeFund',
  },
  {
    label: 'Non Regulated Fund: PE Fund',
    value: 'NonRegulatedFundPEFund',
  },
  {
    label: 'Non Regulated Fund: QAIF',
    value: 'NonRegulatedFundQAIF',
  },
  {
    label: 'Non Regulated Fund: AIF',
    value: 'NonRegulatedFundAIF',
  },
  {
    label: 'Multi/Separately-Managed Fund: SMA',
    value: 'MultiSeparatelyManagedFundSMA',
  },
  {
    label: 'Multi/Separately-Managed Fund: ERISA Plan',
    value: 'MultiSeparatelyManagedFundERISAPlan',
  },
  {
    label: 'Multi/Separately-Managed Fund: CIT',
    value: 'MultiSeparatelyManagedFundCIT',
  },
  {
    label: 'Multi/Separately-Managed Fund: Endowment',
    value: 'MultiSeparatelyManagedFundEndowment',
  },
  {
    label: 'Insurance Co (General Account)',
    value: 'InsuranceCoGeneralAccount',
  },
  {
    label: 'Insurance Co (Separate Account)',
    value: 'InsuranceCoSeparateAccount',
  },
  {
    label: 'Asset Manager',
    value: 'AssetManager',
  },
  {
    label: 'Fund Manager',
    value: 'FundManager',
  },
  {
    label: 'Other',
    value: 'Other',
  },
  {
    label: 'Borrower-Corporate',
    value: 'BorrowerCorporate',
  },
  {
    label: 'Borrower-LLC',
    value: 'BorrowerLLC',
  },
  {
    label: 'Borrower-Individual',
    value: 'BorrowerIndividual',
  },
  {
    label: 'Borrower-Partnership',
    value: 'BorrowerPartnership',
  },
]

export const rateTypeOptions: SelectItem[] = [
  {
    label: 'Overnight',
    value: 'Overnight',
  },
  {
    label: 'One Week',
    value: 'OneWeek',
  },
  {
    label: 'Two Weeks',
    value: 'TwoWeeks',
  },
  {
    label: 'Three Weeks',
    value: 'ThreeWeeks',
  },
  {
    label: 'One Month',
    value: 'OneMonth',
  },
  {
    label: 'Two Months',
    value: 'TwoMonths',
  },
  {
    label: 'Three Months',
    value: 'ThreeMonths',
  },
  {
    label: 'Four Months',
    value: 'FourMonths',
  },
  {
    label: 'Five Months',
    value: 'FiveMonths',
  },
  {
    label: 'Six Months',
    value: 'SixMonths',
  },
  {
    label: 'Seven Months',
    value: 'SevenMonths',
  },
  {
    label: 'Eight Months',
    value: 'EightMonths',
  },
  {
    label: 'Nine Months',
    value: 'NineMonths',
  },
  {
    label: 'Ten Months',
    value: 'TenMonths',
  },
  {
    label: 'Eleven Months',
    value: 'ElevenMonths',
  },
  {
    label: 'One Year',
    value: 'OneYear',
  },
]

export const rateTypeOptionsNewIndex: SelectItem[] = [
  {
    label: 'One Week',
    value: 'OneWeek',
  },
  {
    label: '1 Month',
    value: 'OneMonth',
  },
  {
    label: '4 Months',
    value: 'FourMonths',
  },
  {
    label: '7 Months',
    value: 'SevenMonths',
  },
  {
    label: '10 Months',
    value: 'TenMonths',
  },
  {
    label: 'Two Weeks',
    value: 'TwoWeeks',
  },
  {
    label: '2 Months',
    value: 'TwoMonths',
  },
  {
    label: '5 Months',
    value: 'FiveMonths',
  },
  {
    label: '8 Months',
    value: 'EightMonths',
  },
  {
    label: '11 Months',
    value: 'ElevenMonths',
  },
  {
    label: 'Three Weeks',
    value: 'ThreeWeeks',
  },
  {
    label: '3 Months',
    value: 'ThreeMonths',
  },
  {
    label: '6 Months',
    value: 'SixMonths',
  },
  {
    label: '9 Months',
    value: 'NineMonths',
  },
  {
    label: '1 Year',
    value: 'OneYear',
  },
]

export const roundingOptions: Option[] = [
  {
    label: 'No Rounding',
    value: 'NoRounding',
  },
  {
    label: '5 decimal places',
    value: 'Five',
  },
  {
    label: 'Round Nearest 1/4th',
    value: 'NearestFourth',
  },
  {
    label: 'Round Upward 1/4th',
    value: 'UpwardFourth',
  },
  {
    label: 'Round Downward 1/4th',
    value: 'DownwardFourth',
  },
  {
    label: 'Round Nearest 1/8th',
    value: 'NearestEighth',
  },
  {
    label: 'Round Upward 1/8th',
    value: 'UpwardEighth',
  },
  {
    label: 'Round Downward 1/8th',
    value: 'DownwardEighth',
  },
  {
    label: 'Round Nearest 1/16th',
    value: 'NearestSixteenth',
  },
  {
    label: 'Round Upward 1/16th',
    value: 'UpwardSixteenth',
  },
  {
    label: 'Round Downward 1/16th',
    value: 'DownwardSixteeth',
  },
  {
    label: 'Round Nearest 1/10th',
    value: 'NearesTenth',
  },
  {
    label: 'Round Upward 1/10th',
    value: 'UpwardTenth',
  },
  {
    label: 'Round Downward 1/10th',
    value: 'DownwardTenth',
  },
  {
    label: 'Round Nearest 1/100th',
    value: 'NearestHundredth',
  },
  {
    label: 'Round Upward 1/100th',
    value: 'UpwardHundredth',
  },
  {
    label: 'Round Downward 1/100th',
    value: 'DownwardHundredth',
  },
]

export const staticFacilityTypes: Option[] = [
  {
    label: 'Revolving Credit Facility (RCF)',
    value: 'RCF',
  },
  {
    label: 'Multicurrency Revolving Credit Facility (MRCF)',
    value: 'MRCF',
  },
  {
    label: 'Term Loan (TL)',
    value: 'TL',
  },
  {
    label: 'Term Loan A (TLA)',
    value: 'TLA',
  },
  {
    label: 'Term Loan B (TLB)',
    value: 'TLB',
  },
  {
    label: 'Term Loan C (TLC)',
    value: 'TLC',
  },
  {
    label: 'Delayed Draw Term Loan (DDTL)',
    value: 'DDTL',
  },
  {
    label: 'Bridge Loan (BL)',
    value: 'BL',
  },
  {
    label: 'Letter of Credit Facility (LCF)',
    value: 'LCF',
  },
  {
    label: 'Asset Based Lending (ABL)',
    value: 'ABL',
  },
  {
    label: 'Reserve Based Lending (RBL)',
    value: 'RBL',
  },
]

export const paymentInstructionTypeOptions: Option[] = [
  { label: 'Standard', value: 'Standard' },
  { label: 'Intermediary', value: 'Intermediary' },
  { label: 'ISO20022', value: 'ISO20022' },
]

export const NewFacilityTypes: Option[] = [
  { label: 'Revolving', value: 'Revolving' },
  { label: 'Term', value: 'Term' },
]

export const AmortizationAdministrativeAgencyFeeTypes: Option[] = [
  { label: 'In Arrears', value: 'In_Arrears' },
  { label: 'In Advance', value: 'In_Advance' },
]

export const SubLimitsTypes: Option[] = [
  { label: 'Swing Line', value: 'SwingLine' },
  { label: 'Letter of Credit Issuance', value: 'LetterOfCreditIssuance' },
  { label: 'Currency', value: 'SpecificCurrencies' },
  { label: 'Borrower', value: 'Borrower' },
]

export const EntityDocumentTags: Option[] = [
  { label: 'Admin Detail Form', value: 'AdminDetailForm' },
  { label: 'W-9 Tax Form', value: 'W9TaxForm' },
  { label: 'Articles Of Incorporation', value: 'ArticlesOfIncorporation' },
]

export const DealDocumentTags: Option[] = [
  { label: 'Credit Agreement', value: 'CreditAgreement' },
  { label: 'Draft Credit Agreement', value: 'DraftCreditAgreement' },
  { label: 'Fee Letter', value: 'FeeLetter' },
  { label: 'Security Agreement', value: 'SecurityAgreement' },
  { label: 'Amendment', value: 'Amendment' },
]

export const LoanActionType: Option[] = [
  { label: 'Rollover With Principal', value: 'RolloverWithPrincipal' },
  { label: 'Rollover With New Borrowing', value: 'RolloverWithNewBorrowing' },
  { label: 'Rollover With PIK', value: 'RolloverWithPik' },
  { label: 'Rollover', value: 'Rollover' },
  { label: 'New Borrowing', value: 'NewBorrowing' },
  { label: 'Prepayment', value: 'Prepayment' },
  { label: 'Mandatory Payment', value: 'MandatoryPayment' },
  { label: 'PIK', value: 'PIK' },
  { label: 'Rate Setting', value: 'RateSetting' },
  { label: 'Loan Increase', value: 'LoanIncrease' },
  { label: 'Loan Conversion', value: 'LoanConversion' },
]

export const LocActionType: Option[] = [
  { label: 'Letter of Credit Creation', value: 'LocCreation' },
  { label: 'Letter of Credit Terminated', value: 'LocTerminated' },
  ]

export const PaymentTransactionType: Option[] = [
  { label: 'New Borrowing', value: 'NewBorrowingTransaction' },
  { label: 'Principal Payment', value: 'PrincipalPaymentTransaction' },
  {
    label: 'Principal Payment With Interest',
    value: 'PrincipalWithInterestPaymentTransaction',
  },
  {
    label: 'Full Payment With Interest',
    value: 'FullPrincipalWithInterestPaymentTransaction',
  },
  {
    label: 'Mandatory Payment', value: 'MandatoryPrincipalPaymentTransaction'
  },
  {
    label: 'Mandatory Payment with Interest', value: 'MandatoryPrincipalPaymentWithInterestTransaction'
  },
  {
    label: 'Prepayment', value: 'PrepaymentTransaction'
  },
  {
    label: 'Prepayment With Interest', value: 'PrepaymentWithInterestTransaction'
  },
  { label: 'Interest Payment', value: 'InterestPaymentTransaction' },
  {
    label: 'New Swingline Borrowing',
    value: 'NewSwingLineBorrowingTransaction',
  },
  { label: 'Fee Payment', value: 'FeePaymentTranscation' },
]

export const ServicingPrincipalPaymentTransactionType: Option[] = [
  {
    label: 'Full Payment With Interest',
    value: 'FullPaymentPrincipalWithInterest',
  },
  {
    label: 'Mandatory Payment', value: 'Mandatory'
  },
  {
    label: 'Mandatory Payment with Interest', value: 'MandatoryWithInterest'
  },
  {
    label: 'Prepayment', value: 'PrepaymentPrincipal'
  },
  {
    label: 'Prepayment With Interest', value: 'PrepaymentPrincipalWithInterest'
  },
  { label: 'Interest Payment', value: 'InterestPayment' },
]

export const IndexType: Option[] = [
  { label: 'Term', value: 'TermIndex' },
  { label: 'Fixed', value: 'FixedIndex' },
  { label: 'Overnight', value: 'OvernightIndex' },
  { label: 'Floating', value: 'FloatingIndex' },
]

export const PaymentMethod: Option[] = [
  { label: 'pacs.008', value: 'Pacs008' },
  { label: 'pacs.009', value: 'Pacs009' },
]

export const LetterOfCreditType: Option[] = [
  { label: 'Standby', value: 'Standby' },
  { label: 'Commercial', value: 'Commercial' },
]

export const LetterOfCreditSubtype: Option[] = [
  { label: 'Financial', value: 'Financial' },
  { label: 'Performance', value: 'Performance' },
]

export const autoExtendNotificationPeriod: Option[] = [
  { label: '1 Day', value: '1' },
  { label: '2 Days', value: '2' },
  { label: '3 Days', value: '3' },
  { label: '4 Days', value: '4' },
  { label: '5 Days', value: '5' },
  { label: '6 Days', value: '6' },
  { label: '7 Days', value: '7' },
]

export const calendarYears: Option[] = [
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
  { label: '2025', value: '2025' },
  { label: '2026', value: '2026' },
  { label: '2027', value: '2027' },
  { label: '2028', value: '2028' },
  { label: '2029', value: '2029' },
  { label: '2030', value: '2030' },
]

export const amortizationsStatus: Option[] = [
  { label: 'Paid', value: 'Paid' },
  { label: 'Past Due', value: 'Past_Due' },
  { label: 'Active', value: 'Active' },
  { label: 'Draft', value: 'Draft' },
]

export const todoTaskType: Option[] = [
  { label: 'Borrower Query', value: 'BorrowerQuery' },
  { label: 'Lender Query', value: 'LenderQuery' },
  { label: 'Deliverable', value: 'Deliverable' },
  { label: 'Training', value: 'Training' },
  { label: 'Project', value: 'Project' },
  { label: 'Other', value: 'Other' },
]

export const todoPriority: Option[] = [
  { label: 'High', value: 'High' },
  { label: 'Medium', value: 'Medium' },
  { label: 'Low', value: 'Low' },
]

export const jobSchedule: Option[] = [
  { label: 'Daily', value: 'DailyJob' },
  { label: 'Monthly', value: 'MonthlyJob' },
  { label: 'Quarterly', value: 'QuarterlyJob' },
  { label: 'Semi-Yearly', value: 'SemiYearlyJob' },
  { label: 'Yearly', value: 'YearlyJob' },
]

export const pricingGridTypeOptions: Option[] = [
  { label: 'Financials', value: 'FinancialsPricingGrid' },
  { label: 'Credit Ratings', value: 'CreditRatingsPricingGrid' },
  { label: 'Schedule', value: 'SchedulePricingGrid' },
]

export const pricingGridMarginOrFeeTypeOptions: Option[] = [
  { label: 'Margin', value: 'MarginPricingGrid' },
  { label: 'Fee', value: 'FeePricingGrid' },
  { label: 'Both', value: 'BothPricingGrid' },
]

export const kycStatusOptions: Option[] = [
  { label: 'Pending', value: 'PendingKycStatus' },
  { label: 'In Review', value: 'InReviewKycStatus' },
  { label: 'Validated', value: 'ValidatedStatus' },
]

export const NoticeTypeOptions: Option[] = [
  {value: 'NewBorrowingNotice', label : 'New {index} Borrowing'},
  {value: 'NewBorrowingBorrowerInvoice', label: 'New {index} Borrowing Invoice'},
  {value: 'RolloverNotice', label: 'Rollover'},
  {value: 'RolloverWithPrincipalNotice', label: 'Rollover With Principal'},
  {value: 'ConversionNotice', label: 'Conversion'},
  {value: 'RateSetNotice', label: 'Rate Set'},
  {value: 'PrincipalPaymentNotice', label: 'Principal Payment'},
  {value: 'InterestPaymentNotice', label: 'Interest Payment'},
  {value: 'FeePaymentNotice', label: 'Fee Payment'},
]

export const LenderTransactionTypeOptions: Option[] = [
  {value: 'Borrowing', label :"Borrowing"},
  {value: 'Paydown', label: "Paydown"},
  {value: 'InterestPaydown', label:"Interest Paydown"},
  {value: 'AssignmentSell', label:"Assignment (Sell)"},
  {value: 'AssignmentBuy', label:"Assignment (Buy)"},
  {value: 'PIKTransaction', label:"PIK"},
  {value: 'Conversion', label:"Conversion"},
  {value: 'FacilityAmendmentTransaction', label:"Facility Amendment"},
  {value: 'RateSettingTransaction', label:"RateSetting"},
]
