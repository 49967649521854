import {
    Group,
    Stack,
    Text,
} from '@mantine/core'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getEntity } from 'app/state/ducks/entities/selectors'
import { loadOneEntity } from 'app/state/ducks/entities/thunks'
import { ContractType } from 'app/models/common-types'
import { loadNoteByContract } from 'app/state/ducks/notes/thunks'
import NotesAndAttachment from 'app/views/components/notes-and-attachemnt/notes-and-attachment-full'
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle'
import { EntityTypeOptions, kycStatusOptions } from 'app/models/dropdown-options'
import { entityTypeWithoutFundManager } from 'app/models/entity-params'
import { DetailSection } from 'app/views/components/detailSections/detail-section'
import { getEntityProfiles } from 'app/state/ducks/entity-profile/selectors'


export default function EntityPage() {
    const { entityId, status } = useParams()
    const entity = useSelector(getEntity)
    const entityProfiles = useSelector(getEntityProfiles)

    const dispatch = useDispatch()


    useEffect(() => {
        if (entityId) {
            dispatch(loadOneEntity(entityId, status ?? 'Inactive'))
            dispatch(loadNoteByContract(entityId, ContractType.Entities))
        }
    }, [entityId])

    const isFundManager = !entityTypeWithoutFundManager.includes(entity?.entityType);

    const entityDetailsObjectArray = [
        { label: 'Entity Type', value: EntityTypeOptions.find(option => option.value === entity?.entityType)?.label || 'N/A' },
        { label: isFundManager ? 'Fund Manager' : 'Parent', value: isFundManager ? entity?.fundManager : entity?.parent },
        { label: 'KYC Status', value: kycStatusOptions.find(option => option.value === entity?.kycStatus)?.label || 'Pending' },
        { label: 'Institution Type', value: 'Institution Type' },
        { label: 'Entity Profile', value: entity?.entityProfile.map(profile => entityProfiles.find(option => option.id === profile.id)?.name || '').filter(Boolean).join(', ') ?? 'N/A' },
        { label: isFundManager ? 'Fund Manager MEI' : 'Parent MEI', value: isFundManager ? entity?.fundManagerMei : entity?.parentMei },
    ]

    const addressDetailsObjectArray = [
        { label: 'Address', value: entity?.address },
        { label: 'ZIP/Postal Code', value: entity?.zipCode },
        { label: 'State/Region', value: entity?.state },
        { label: 'City', value: entity?.city },
        { label: 'Address Line 2', value: entity?.address2 },
        { label: 'Country of Incorporation', value: entity?.countryOfIncorporation },
    ]

    const identifiersDetailsObjectArray = [
        { label: 'CRN #', value: entity?.crn },
        { label: 'Internal Entity ID', value: entity?.id },
        { label: 'GIIN', value: entity?.giin },
        { label: 'EIN', value: entity?.ein },
        { label: 'MEI', value: entity?.mei },
        { label: 'LEI', value: entity?.legalEntityId },
        { label: 'NAIC Code', value: entity?.naicCode },
    ]

    const taxDetailsObjectArray = [
        { label: 'Entity Reference', value: entity?.entityReference },
        { label: 'US TaxForm Status', value: entity?.taxFormStatus },
        { label: 'US Treaty Passport Exp Date', value: entity?.ukTreatyPassportExpiryDate },
        { label: 'US Treaty Passport #', value: entity?.ukTreatyPassportNumber },
        { label: 'US Tax Form Type', value: entity?.usTaxFormType },
        { label: 'Country Of Tax Residence', value: entity?.countryOfTaxResidence },
    ]

    return (
        <Stack w="100%">
            <Stack w="100%" className='sectionRemoveGap'>
                <SectionTitle>Entity Overview</SectionTitle>
                <Stack w='100%' className='sectionRemoveGap sectionBody'>
                    <Group w='100%' noWrap className='sectionRemoveGap' align='top'>
                        <Stack w='50%' className='sectionDetailBody'>
                            <Text className='sectionDetailHeader'>Entity Details</Text>
                            <DetailSection detailObjArr={entityDetailsObjectArray} columnCount={3} />
                        </Stack>
                        <Stack w='50%' className='sectionDetailBody'>
                            <Text className='sectionDetailHeader'>Address</Text>
                            <DetailSection detailObjArr={addressDetailsObjectArray} columnCount={3} />
                        </Stack>
                    </Group>
                    <Group w='100%' noWrap className='sectionRemoveGap' align='top'>
                        <Stack w='50%' className='sectionDetailBody'>
                            <Text className='sectionDetailHeader'>Identifiers</Text>
                            <DetailSection detailObjArr={identifiersDetailsObjectArray} columnCount={3} />
                        </Stack>
                        <Stack w='50%' className='sectionDetailBody'>
                            <Text className='sectionDetailHeader'>Tax</Text>
                            <DetailSection detailObjArr={taxDetailsObjectArray} columnCount={3} />
                        </Stack>
                    </Group>
                </Stack>
            </Stack>
            <Group w="100%" noWrap mt='30px'>
                <NotesAndAttachment
                    itemId={entityId ?? ''}
                    type={ContractType.Entities}
                    itemAdmin={entity?.maker ?? ''}
                />
            </Group>
        </Stack>
    )
}