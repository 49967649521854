import {
  Group,
  Stack,
  Title,
} from '@mantine/core'
import StatusPill from '../../common/pill-status'
import { FacilityParams } from 'app/models/facility-params'


interface props {
  facility: FacilityParams,
  customTitle?: string
}

export default function FacilityHeader({ facility, customTitle }: props): JSX.Element {
  return (
    <Stack className="detailsHeader">
      <Group noWrap>
        {customTitle ?
          <Title className='detailsTitle'>{customTitle}</Title> :
          <Title className='detailsTitle'>{facility ? facility.name : ''}</Title>}
        <StatusPill
          updated={true}
          state={facility?.status ?? 'Draft'}
          additionalState={facility?.amendmentType ?? ''}
        />
      </Group>
    </Stack>
  )
}
