import {
    Group,
    Stack,
    Text
} from '@mantine/core'
import { EntityTypeOptions } from 'app/models/dropdown-options'
import { EntityParams, entityTypeWithoutFundManager } from 'app/models/entity-params'
import { getEntityProfiles } from 'app/state/ducks/entity-profile/selectors'
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle'
import { DetailSection } from 'app/views/components/detailSections/detail-section'
import { useSelector } from 'react-redux'

type Props = {
    entity: EntityParams
}

export default function EntityOverviewSection({ entity }: Props) {

    const entityProfiles = useSelector(getEntityProfiles)

    const isFundManager = !entityTypeWithoutFundManager.includes(
        entity?.entityType,
    )

    const entityDetailsObjectArray = [
        {
            label: 'Entity Type',
            value:
                EntityTypeOptions.find(option => option.value === entity?.entityType)
                    ?.label || 'N/A',
        },
        {
            label: 'Entity Profile',
            value:
                entity?.entityProfile
                    .map(
                        profile =>
                            entityProfiles.find(ep => ep.id === profile.id)
                                ?.name || '',
                    )
                    .filter(Boolean)
                    .join(', ') ?? 'N/A',
        },
        {
            label: 'MEI',
            value: entity?.mei,
        },
        {
            label: 'GIIN',
            value: entity?.giin,
        },
        {
            label: isFundManager ? 'Entity Profile' : 'Parent',
            value: isFundManager ? entity?.fundManager : entity?.parent,
        },
        {
            label: isFundManager ? 'Fund Manager MEI' : 'Parent MEI',
            value: isFundManager ? entity?.fundManagerMei : entity?.parentMei,
        },
    ]

    const addressDetailsObjectArray = [
        { label: 'Address', value: entity?.address },
        { label: 'Address Line 2', value: entity?.address2 },
        { label: 'City', value: entity?.city },
        { label: 'State/Region', value: entity?.state },
        { label: 'ZIP/Postal Code', value: entity?.zipCode },
        {
            label: 'Country/Region',
            value: entity?.country,
        },
    ]

    return (
        <Stack w="100%" className='sectionRemoveGap' mt='30px'>
            <SectionTitle>Entity Overview</SectionTitle>
            <Stack w='100%' className='sectionRemoveGap sectionBody'>
                <Group w='100%' noWrap className='sectionRemoveGap' align='top'>
                    <Stack w='50%' className='sectionDetailBody'>
                        <Text className='sectionDetailHeader'>Entity Details</Text>
                        <DetailSection detailObjArr={entityDetailsObjectArray} columnCount={3} />
                    </Stack>
                    <Stack w='50%' className='sectionDetailBody'>
                        <Text className='sectionDetailHeader'>Address</Text>
                        <DetailSection detailObjArr={addressDetailsObjectArray} columnCount={3} />
                    </Stack>
                </Group>
            </Stack>
        </Stack>
    )
}