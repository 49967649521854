import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { EntitiesProfileDrillPage } from './entities-profile-drill-page'

export default function EntitiesProfilePage(tab: { tab: string }) {
    return (
        <PageLayout>
            <PageTitle pageTitle='Entity Profile' />
            <EntitiesProfileDrillPage tab={tab.tab} />
        </PageLayout>
    )
}
