import React from 'react';
import { Group, Stack, Text } from '@mantine/core';
import { TradeEntryCounterParty } from './trade-entry-counter-party';
import { TradeEntryTable } from './trade-entry-table';
import { TextLabelInput } from 'app/views/components/inputs/TextLabelInput';

export function TradeEntryFormPresentation() {
  return (
    <Stack className="tradeEntryCreationPage">
      {/* Info: Booking Details section */}
      <Text className="tradeEntrySectionTitle">Booking Details</Text>
      <Group position="apart">
        <Group noWrap className="dataEntrySubSection">
          <TextLabelInput label="Client Trade ID">Trade ID</TextLabelInput>
        </Group>
        <Group noWrap className="dataEntrySubSection">
          <TextLabelInput label="Trader/PM">Trader/PM</TextLabelInput>
        </Group>
        <Group noWrap className="dataEntrySubSection">
          <TextLabelInput label="Desk">Desk</TextLabelInput>
        </Group>
        <Group noWrap className="dataEntrySubSection">
          <TextLabelInput label="Trade Group ID">Trade Group ID</TextLabelInput>
        </Group>
      </Group>

      {/* Info: Counterparties section */}
      <Text className="tradeEntrySectionTitle">Counterparties</Text>
      <Group position="apart">
        <TradeEntryCounterParty title="My Entity" radio={true} />
        <TradeEntryCounterParty title="Counterparty" />
      </Group>

      {/* Info: Terms of Trade */}
      <Text className="tradeEntrySectionTitle">Terms of Trade</Text>
      <Group className="tradeEntryTermsTradeGroup">
        <Group noWrap className="dataEntrySubSection">
          <TextLabelInput label="Trade Date">10-12-2024</TextLabelInput>
        </Group>
        <Group noWrap className="dataEntrySubSection">
          <TextLabelInput label="Trade Type">Primary</TextLabelInput>
        </Group>
        <Group noWrap className="dataEntrySubSection">
          <TextLabelInput label="Trade Association">LSTA</TextLabelInput>
        </Group>
        <Group noWrap className="dataEntrySubSection">
          <TextLabelInput label="Trade Docs">Par</TextLabelInput>
        </Group>
        <Group noWrap className="dataEntrySubSection">
          <TextLabelInput label="Transfer Type">Assignment</TextLabelInput>
        </Group>
        <Group noWrap className="dataEntrySubSection">
          <TextLabelInput label="Interest Treatment">SWOA</TextLabelInput>
        </Group>
        <Group noWrap className="dataEntrySubSection">
          <TextLabelInput label="Assignment Fee Split">Buyer Pays Half</TextLabelInput>
        </Group>
      </Group>

      {/* Info: Traded Deals section */}
      <Text className="tradeEntrySectionTitle">Traded Deal</Text>
      <Group noWrap className="dataEntryLabelValueWithDivider">
        <TextLabelInput label="Deal Name">Deal Name</TextLabelInput>
      </Group>
      <Group noWrap className="tradeEntryTradeRow">
        <Group noWrap className="tradeEntryLabelInputSmall">
          <TextLabelInput label="CUSIP" divider={false}>
            US5738493
          </TextLabelInput>
        </Group>
        <Group noWrap className="tradeEntryLabelInputSmall">
          <TextLabelInput label="LX ID" divider={false}>
            LX388478
          </TextLabelInput>
        </Group>
        <Group noWrap className="tradeEntryLabelInputMedium">
          <TextLabelInput label="Deal ID" divider={false}>
            Deal ID
          </TextLabelInput>
        </Group>
        <Group noWrap className="tradeEntryLabelInputMedium">
          <TextLabelInput label="Client Deal ID" divider={false}>
            Client Deal ID
          </TextLabelInput>
        </Group>
      </Group>

      {/* Info: Facilities Table */}
      <Text className="tradeEntrySectionTitle">Traded Facilities</Text>
      <TradeEntryTable />

      <Group noWrap className="tradeEntrySubmissionSection">
        <Text className="tradeEntrySubmissionCancel">Cancel</Text>
        <Text className="tradeEntrySubmissionCreate">Create Trade</Text>
      </Group>
    </Stack>
  );
}
